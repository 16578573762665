<div>
    <div class="row mb-6">
        <div class="d-flex justify-content-end">
            <button
                type="button"
                (click)="toggleFormSpecialties()"
                class="btn btn-sm btn-light btn-active-color-dark"
            >
                <span class="svg-icon svg-icon-3">
                    <app-svg name="plus"></app-svg>
                </span>
                Adicionar nova especialidade
            </button>
        </div>
    </div>

    <div *ngIf="showSpecialties" class="card-specialties mb-8 border-2  border border-bottom-2 p-4">
        <form action="#" (ngSubmit)="onSubmit()" class="w-100" [formGroup]="form">
            <div class="col-lg-12 mb-8">
                <h3 class="label-title text-center mb-5">ESPECIALIDADES</h3>
                <ng-select2
                    width="100%"
                    id="specialtiesList"
                    [options]="options"
                    formControlName="specialties"
                    class="placeholder-gray-500 d-flex align-items-center"
                    name="specialties"
                    [formGroup]="form"
                    [data]="specialtiesItems"
                    placeholder="Selecione as especialidades deste cliente"
                >
                </ng-select2>
            </div>
            <div class="d-flex justify-content-center">
                <button
                    type="submit"
                    class="btn btn-primary cursor-pointer"
                    [disabled]="form.invalid"
                >
                    Salvar
                </button>
            </div>
        </form>
    </div>

    <div
        *ngFor="let specialties of specialsClient" class="list-wrapper"
        (click)="removeSpecialtie(specialties.id)"
        [ngClass]="{ 'item-select': specialtiesRemoved.includes(specialties.id) }"
    >
        <span class="bg-success bullet bullet-vertical h-40px me-6"></span>
        <strong> {{ specialties.text }} </strong>
    </div>

    <button
        class="btn btn-danger cursor-pointer"
        *ngIf="specialtiesRemoved.length > 0"
        (click)="clearSpecialtiesRemoveds()"
    >
        limpar itens selecionados
    </button>

    <div *ngIf="specialsClient && !specialsClient.length">
        <h3 class="text-danger text-center">
            Não há especialidades relacionados a este cliente
        </h3>
    </div>
</div>
