import { Injectable } from '@angular/core'
import * as moment from 'moment'
import jwtDecode from 'jwt-decode'
import { VersionService } from '../version/version.service'
import { Validators } from '@angular/forms'

@Injectable({
	providedIn: 'root',
})
export class HelpersService {
	constructor(private versionService: VersionService) {}

	private controlMessageError(typeError: string, control?: string): string {
		switch (typeError) {
			case 'required':
				return `O campo ${control} é requerido!`
			case 'email':
				return `E-mail inválido!`
			case 'cpfInvalid':
				return `CPF inválido!`
			case 'notSamePassword':
				return `A confirmação da senha não corresponde!`
			default:
				return ''
		}
	}

	public getControlMessageError(objError: any, control?: string): string {
		const typeError = Object.keys(objError).length
			? Object.keys(objError)[0]
			: ''
		return this.controlMessageError(typeError, control)
	}

	public converterSnakeToCamelCase(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[
					d.replace(/(\_\w)/g, (k) => {
						return k[1].toUpperCase()
					})
				] = obj[d]
			}
		}
		return newObj
	}

	public converterCamelCaseToSnake(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[this.camelToUnderscore(d)] = obj[d]
			}
		}
		return newObj
	}

	public camelToUnderscore(key: string): string {
		const result = key.replace(/([A-Z])/g, ' $1')
		return result.split(' ').join('_').toLowerCase()
	}

	decodePayloadJWT(token: string): any {
		try {
			return jwtDecode(token)
		} catch (Error) {
			return null
		}
	}

	public getDuration(date: Date | string): string {
		const end = moment()
		const init = moment.utc(date).local()
		const duration = moment.duration(end.diff(init))
		const durationYears = duration.years()
		const durationMonths = duration.months()
		const durationDays = duration.days()
		const durationHours = duration.hours()
		const durationMinutes = duration.minutes()
		let msg = `__YEARS____MONTHS____DAYS____HOURS____MINUTES__`

		const msgYear = durationYears ? durationYears + 'ano(s) ' : ''
		const msgMonth = durationMonths ? durationMonths + 'mês(es) ' : ''
		const msgDays = durationDays ? durationDays + 'd ' : ''
		const msgHours = durationHours ? durationHours + 'h ' : ''
		const msgMinutes = durationMinutes ? durationMinutes + 'm ' : '0m'

		if (date) {
			return msg
				.replace('__YEARS__', msgYear)
				.replace('__MONTHS__', msgMonth)
				.replace('__DAYS__', msgDays)
				.replace('__HOURS__', msgHours)
				.replace('__MINUTES__', msgMinutes)
		}
		return '-- --'
	}

	public getDateToAge(date: Date | string): string {
		if (!date) return '--'
		return `${moment().diff(moment.utc(date).local(), 'years')}`
	}

	public getDate(date: Date | string, withHours: boolean = false): string {
		if (!date) return '--'
		let format = withHours ? 'DD/MM/YYYY -- HH:mm' : 'DD/MM/YYYY'
		let dateString = moment.utc(date).local().format(format)
		return dateString.replace('--', 'às')
	}

	public converterTsInDate(
		date: Date | string,
		isTs: boolean = false
	): string {
		if (!date) return '--'
		const today = moment()
		const yesterday = moment().add(-1, 'days')
		let newDate = moment.utc(date).local()
		if (isTs) {
			newDate = moment.utc(date, 'X').local()
		}
		let dateString = `${newDate.format('DD/MM/YYYY -- HH:mm')}`

		if (newDate.format('DD/MM/YYYY') === today.format('DD/MM/YYYY')) {
			dateString = `hoje ${newDate.format('-- HH:mm')}`
		} else if (
			newDate.format('DD/MM/YYYY') === yesterday.format('DD/MM/YYYY')
		) {
			dateString = `ontem ${newDate.format('-- HH:mm')}`
		}

		return dateString.replace('--', 'às')
	}

	public scrollToElement($element: any): void {
		$element.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		})
	}

	public getQueryVariable(variable: string): string {
		const query = window.location.search.substring(1)
		const vars = query.split('&')
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=')
			if (pair[0] == variable) return pair[1]
		}
		return ''
	}

	public transformNumber(n: string, toFixed = 2) {
		return (Math.round(parseFloat(n) * 100) / 100).toFixed(toFixed)
	}

	public transformString(value: any, valueDefault: string = ''): string {
		return value !== null && value !== undefined ? `${value}` : valueDefault
	}

	public removeNullValuesFromObject(obj: any) {
		Object.keys(obj).forEach((key) => {
			if (obj[key] === null || obj[key] === undefined) {
				delete obj[key]
			}
		})

		return obj
	}

	public clearLocalStorage(currentVersion: string) {
		let version = localStorage.getItem('version') || '0.0.0'

		currentVersion = currentVersion.split('=')[1]
		currentVersion = currentVersion.replace('\n', '')

		if (version !== currentVersion) {
			localStorage.clear()
			localStorage.setItem('version', currentVersion)

			//@ts-ignore
			window.location = '/login'
		}
	}

	public setDevicesInList(
		mediaDevices: MediaDeviceInfo[],
		list: any[],
		kind: string
	): void {
		let count = 1
		mediaDevices.forEach((mediaDevice) => {
			if (mediaDevice.kind === kind) {
				list.push({
					label:
						mediaDevice.label ||
						`${kind.toLocaleLowerCase()} ${count}`,
					value: mediaDevice.deviceId,
				})
				count++
			}
		})
	}

	public addMaskCpf(v: string) {
		if (!v) return '--'
		v = v.replace(/\D/g, '')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
		return v
	}

	public removeBlankAttributesFromObject(obj: any) {
		Object.keys(obj).forEach(
			(k: string | number) => obj[k] == null && delete obj[k]
		)
	}

	FormatStringData(data: any) {
		const dia = data.split('/')[0]
		const mes = data.split('/')[1]
		const ano = data.split('/')[2]

		return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2)
	}

    public redirectPage(timeOut: number, path: string) {
        setTimeout(() => {
            window.location.href = path
        }, timeOut)
    }

    public reloadPage(timeOut: number) {
        setTimeout(() => {
            window.location.reload()
        }, timeOut)
    }

    public groupFormDinamic(state: string, client?: any) {
        switch(state) {
            case 'contrato':
                return { contractType: ['', Validators.required], content: ['', Validators.required], }
            case 'editar':
                return {
                    name: [client?.name || '', [Validators.required]],
                    usesMemed: [client?.usesMemed || false, [Validators.required]],
                    externalConference: [client?.externalConference || '', [Validators.maxLength(100)]],
                    appointmentsExpiration: [client?.appointmentsExpiration || '', [Validators.required]],
                    logo: [client?.logo, [Validators.required]],
                }
            default:
                return {
                    name: ['', [Validators.required]],
                    logo: [''],
                    usesMemed: [client?.usesMemed || false, [Validators.required]],
                    appointmentsExpiration: ['90'],
                    contractType: ['', [Validators.required]],
                    content: ['', [Validators.required]],
                    specialties: ['', [Validators.required]],
                    nameProtocol: ['', [Validators.required]],
                    frontendDescription: ['', [Validators.required]],
                    frontendName: ['', [Validators.required]],
                    description: ['', [Validators.required]],
                    externalConference: [client?.externalConference || '', [Validators.maxLength(100)]],
                }
        }
    }
}
