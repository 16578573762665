<div class="card-header d-flex justify-content-end mb-5">
    <div class="row w-100">
		<div class="col-lg-9">
			<div
				class="d-flex align-items-center position-relative w-100 m-0"
			>
				<span
					class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
				>
					<app-svg name="search"></app-svg>
				</span>
				<input
					type="text"
					class="form-control form-control-solid ps-13"
					(keyup)="searchCLient($event.target)"
					placeholder="Buscar empresa"
				/>
			</div>
		</div>
		<div class="col-lg-3">
			<div class="w-100 h-100 d-flex justify-content-end align-items-center">
                <a
                    routerLink="/panel/cliente"
                    class="w-100 btn btn-sm btn-light btn-active-color-dark"
                >
                    <span class="svg-icon svg-icon-3">
                        <app-svg name="plus"></app-svg>
                    </span>
                    Adicionar nova empresa
                </a>
            </div>
		</div>
	</div>
</div>

<div class="row">
	<app-table-list [listBody]="listFilters" [listExist]="clientList" [listHeader]="ClientListHeader">
		<ng-template let-item #body>
			<td width="50" class="picture radius cursor-pointer">
				<a [routerLink]="'/panel/cliente/' + item.id">
					<app-image
						[path]="item.logo || '/assets/images/placeholder.gif'"
						[title]="'logo ' + item.name"
					></app-image>
				</a>
			</td>
			<td width="230" class="level">
                <a [routerLink]="'/panel/cliente/' + item.id">
                    <span class="text-dark fw-bolder text-hover-primary mb-1 fs-6" >
                        {{ item.name || '--' }}
                    </span>
                </a>
			</td>
            <td class="text-center">
                <span class="badge mx-1" [class]="!item.usesMemed ? 'badge-light-danger ': 'badge-light-success'">
                    {{ item.usesMemed ? 'Sim' : 'Não' }}
                </span>
            </td>
            <td class="text-center">
                <span>
                    {{ item.employeesAmount }}
                </span>
            </td>
            <td class="text-end d-flex">
				<button
					(click)="deleteClient(item)"
					class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
				>
					<span class="svg-icon svg-icon-3">
						<app-svg name="delete"></app-svg>
					</span>
				</button>
			</td>
		</ng-template>
	</app-table-list>
</div>
