import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ControlSelectOptions } from '../../../models/control-select-options'

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
    @Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
    @Input() name: string | number | null = ''
	@Input() inputModel: any
	@Input() labelName?: string
	@Input() placeholder?: string
	@Input() options?: ControlSelectOptions[]
    @Input() disabled: boolean = false
	@Output() changeValue: EventEmitter<any> = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	onChange(event: any): void {
		this.changeValue.emit(event.target.value)
	}
}
