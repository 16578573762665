import { Component, Input, OnInit } from '@angular/core'
import { ClientInfo } from 'src/app/models/client'
import { Users } from '../../../models/users'

@Component({
	selector: 'app-card-profile-header',
	templateUrl: './card-profile-header.component.html',
	styleUrls: ['./card-profile-header.component.scss'],
})
export class CardProfileHeaderComponent implements OnInit {
	@Input() profile?: Users
	@Input() client?: ClientInfo
	@Input() profileTitle?: string = ''
	@Input() content: boolean = true
	@Input() editProfile?: boolean = false
	@Input() editProfileClient?: boolean = false

	constructor() {}

	ngOnInit(): void {}
}
