<div>
    <app-form-protocol
        *ngIf="show"
        [clientId]="clientIdSent"
        [examsClient]="examsList"
        [protocolId]="protocolId"
        [protocolsClient]="protocolsClient"
        (submiting)="refreshProtocols($event)"
        [form]="form"
    ></app-form-protocol>

    <div class="loading-protocol">
        <app-image
            *ngIf="!show"
            title="loading..."
            path="/assets/images/temp/loading.svg">
        </app-image>
    </div>

    <div class="d-flex justify-content-center" *ngIf="show">
        <button
            type="button"
            (click)="getClientIdSent()"
            class="btn btn-primary cursor-pointer btn-submit"
            [disabled]="form.invalid"
        >
            <span *ngIf="!submiting"> Salvar Alterações </span>
            <span *ngIf="submiting">
                <app-image class="image-load" title="loading..."
                    path="/assets/images/loading-white.gif"
                ></app-image>
            </span>
        </button>
    </div>
</div>
