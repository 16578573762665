import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { UserService } from '../../services/user/user.service'
import { Users } from '../../models/users'
import { ClientInfo } from '../../models/client'
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper'
import { ClientService } from 'src/app/services/client/client.service'

@Component({
	selector: 'app-edit-profile-picture',
	templateUrl: './edit-profile-picture.component.html',
	styleUrls: ['./edit-profile-picture.component.scss'],
})
export class EditProfilePictureComponent implements OnInit {
	@Input() profile?: Users
	@Input() client?: ClientInfo
	@Input() profileTitle?: string = ''
	@Input() content: boolean = true
	@Output() idItemDeleted = new EventEmitter()
	@Input() blob?: string = ''
    reload: boolean = true
    image: string = ''

	imageChangedEvent: any = ''
	croppedImage: any = ''
	openModalFile: boolean = false
	transform: ImageTransform = {}
	scale = 1

	constructor(
		private toast: ToastrService,
		private userService: UserService,
		private clientService: ClientService,
	) {}

	ngOnInit(): void {}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event
		if (event.target.value) {
			this.openModalFile = true
		}
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64
	}

	sendFileImageProfile(id?: string) {
        const isClient = this.client ? true : false
		const self = this

		self.userService.postFileProfile(self.croppedImage, isClient, id, {
			fnSuccess() {
				self.onCloseModalFile()
				self.toast.success('Imagem alterada com sucesso')
                self.reload = false
                if(self.profile) {
                    self.getUser(self.profile.id)
                } else if (self.client) {
                    self.getClient(self.client.id)
                }
			},
			fnError(responseError) {
				self.toast.success('Ocorreu um erro' + responseError)
				console.warn(responseError)
			},
		})
	}
	loadImageFailed() {
		this.openModalFile = false
		this.onCloseModalFile()
		this.toast.error(
			'Os formatos de imagems aceito são png | jpeg | bmp | webp | ico',
			'Formato de umagem inválido'
		)
	}
	onCloseModalFile() {
		this.openModalFile = false
		this.imageChangedEvent = ''
		this.croppedImage = ''
	}
	zoomOut() {
		if (this.scale > 1) {
			this.scale -= 0.1
			this.transform = {
				...this.transform,
				scale: this.scale,
			}
		}
	}
	zoomIn() {
		this.scale += 0.1
		this.transform = {
			...this.transform,
			scale: this.scale,
		}
	}
    getClient(id: string) {
        const self = this
        this.clientService.getClient(id, {
            fnSuccess(data) {
                self.reload = true
                self.image = data.logo
            }, fnError(e) {
                console.warn(e)
            }
        })
    }
    getUser(id: string) {
        const self = this
        self.userService.getUser(id, {
            fnSuccess(data) {
                self.reload = true
                self.image = data.profilePictureUrl
            }, fnError(e) {
                console.warn(e)
            }
        })
    }
}
