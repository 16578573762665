import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client/client.service';
import { clientListHeader } from 'src/app/mocks/client';
import { ListHeader } from 'src/app/models/list-header';
import { ModalDefaultComponent } from 'src/app/components/modals/modal-default/modal-default.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { ClientInfo } from 'src/app/models/client';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
    clientList: ClientInfo[] = []
    ClientListHeader: ListHeader[] = clientListHeader
    listFilters: ClientInfo[] = []

    constructor(
		private modalDefault: NgbModal,
        private clientService: ClientService,
        private toast: ToastrService
    ) {}

    ngOnInit(): void {
       this.getAllClients()
    }

    searchCLient(clientSearch: any) {
        const search = clientSearch.value.trim().toLowerCase()
        this.listFilters = this.clientList.filter(({ name }: any) => {
            const clientName = name.trim().toLowerCase()
            return clientName.includes(search)
        })
    }

    getAllClients() {
        const self = this
        this.clientService.getAllClients({
            fnSuccess(data) {
                self.listFilters = data
                self.clientList = data
            },
            fnError(err) {
                console.warn(err)
            }
        })
    }

    deleteClient({ name, id }: any) {
        const self = this
		const modalRef = this.modalDefault.open(ModalDefaultComponent)
		modalRef.componentInstance.title = `Tem certeza que deseja excluir o cliente ${name}?`
		modalRef.componentInstance.desc = 'Essa a ação não poderá ser desfeita.'
		modalRef.componentInstance.callbackConfirmation = () => {
			modalRef.close()
			this.clientService.deleteClient(id, () => {
				this.toast.success('Cliente excluido com sucesso')
                self.getAllClients()
			})
		}
	}
}
