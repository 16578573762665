import { Component, Input, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth/auth.service'

@Component({
	selector: 'app-image-decrypt',
	templateUrl: './image-decrypt.component.html',
	styleUrls: ['./image-decrypt.component.scss'],
})
export class ImageDecryptComponent implements OnInit {
	@Input() path?: string = ''
	@Input() title?: string = ''
	@Input() load: boolean = false
	blob: string = ''

	default?: {
		defaultImage: string
		errorImage: string
	}
	constructor(private authService: AuthService) {}

	ngOnInit(): void {
		this.getAvatar()
	}

	getAvatar() {
		if (this.path) {
			this.authService.getFile(this.path).then((data) => {
				this.blob = URL.createObjectURL(data)
			})
		}
	}
}
