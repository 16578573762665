<div class="card mb-5 mb-xl-10">
	<div class="card-body pt-9 pb-0">
		<div class="d-flex flex-wrap flex-sm-nowrap mb-3">
			<div class="me-7 mb-4">
				<div
					class="symbol w-150px symbol-100px symbol-lg-160px symbol-fixed position-relative"
				>
					<div class="wrapper-profile" *ngIf="editProfile || editProfileClient">
						<app-edit-profile-picture
							[blob]="profile?.profilePictureUrl || client?.logo"
							[profile]="profile"
                            [client]="client"
						></app-edit-profile-picture>
					</div>
				</div>
			</div>
			<div class="flex-grow-1">
				<div
					class="d-flex justify-content-between align-items-start flex-wrap mb-2"
				>
					<div class="d-flex flex-column">
						<div class="d-flex align-items-center mb-2">
							<h2
								class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
							>
								{{ profile?.name || client?.name }}
								{{ profile?.lastName || '' }}
							</h2>
							<a href="#">
								<span
									class="svg-icon svg-icon-1 svg-icon-primary"
								>
									<app-svg name="check"></app-svg>
								</span>
							</a>
						</div>
						<div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
							<span
								*ngIf="client"
								class="badge mx-1"
								[class]="
									!client.usesMemed
										? 'badge-light-danger '
										: 'badge-light-success'
								"
							>
								Memed
							</span>
							<a
								*ngIf="profile?.roles"
								href="#"
								class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
							>
								<span class="svg-icon svg-icon-4 me-1">
									<app-svg name="profile"></app-svg>
								</span>
								<span
									class="mx-1 badge badge-light-success"
									*ngFor="let item of profile?.roles"
								>
									{{ item }}
								</span>
							</a>
							<span
								*ngIf="client?.corporateName"
								class="d-flex align-items-center text-gray-400 text-hover-primary ms-2"
							>
								Nome corporativo: {{ client?.corporateName }}
							</span>
							<a
								*ngIf="profile?.email"
								[href]="'mailto:' + profile?.email"
								class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
							>
								<span class="svg-icon svg-icon-4 me-1">
									<app-svg name="mail-icon"></app-svg>
								</span>
								{{ profile?.email }}
							</a>
						</div>
					</div>
					<div class="d-flex my-4">
						<a href="#" class="btn btn-sm btn-light me-2">
							<span class="svg-icon svg-icon-3 d-none">
								<app-svg name="check-ligth"></app-svg>
							</span>
							<span class="indicator-label">Follow</span>
							<span class="indicator-progress">
								Please wait...
								<span
									class="spinner-border spinner-border-sm align-middle ms-2"
								></span>
							</span>
						</a>
						<app-menu-sub *ngIf="profile">
							<div class="menu-item px-3" >
								<a href="#" class="menu-link px-3"
									>New Ticket</a
								>
							</div>
							<div class="menu-item px-3">
								<a href="#" class="menu-link px-3"
									>New Customer</a
								>
							</div>
							<div class="menu-item px-3">
								<a href="#" class="menu-link px-3"
									>New Contact</a
								>
							</div>
						</app-menu-sub>
					</div>
				</div>
				<div class="d-flex flex-wrap flex-stack">
					<div class="d-flex flex-column flex-grow-1 pe-8">
						<div class="d-flex flex-wrap">
							<div *ngIf="client" class="w-700px d-flex ">
                                <div
                                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                                >
                                    <div class="d-flex align-items-center">
                                        <div class="fs-2 fw-bolder counted">
                                            {{ client.employeesAmount }}
                                        </div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">
                                        Funcionários
                                    </div>
                                </div>
                                <div
                                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                                >
                                    <div class="d-flex align-items-center">
                                        <div class="fs-2 fw-bolder counted">
                                            {{ client.contracts.length }}
                                        </div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">
                                        contratos
                                    </div>
                                </div>
                            </div>
							<div
								*ngIf="false"
								class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
							>
								<div class="d-flex align-items-center">
									<span
										class="svg-icon svg-icon-3 svg-icon-danger me-2"
									>
										<app-svg name="arrow-danger"></app-svg>
									</span>
									<div class="fs-2 fw-bolder counted">75</div>
								</div>
								<div class="fw-bold fs-6 text-gray-400">
									Projects
								</div>
							</div>
						</div>
					</div>
					<div
						*ngIf="false"
						class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"
					>
						<div
							class="d-flex justify-content-between w-100 mt-auto mb-2"
						>
							<span class="fw-bold fs-6 text-gray-400">
								Perfil Completo
							</span>
							<span class="fw-bolder fs-6">50%</span>
						</div>
						<div
							class="h-5px mx-3 w-100 bg-light-primary rounded mb-3"
						>
							<div
								class="bg-success rounded h-5px"
								role="progressbar"
								style="width: 50%"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-content select="[profileHeader]"></ng-content>
	</div>
</div>
<div *ngIf="content" class="card mb-5 mb-xl-10" id="kt_profile_details_view">
	<div class="card-header">
		<div class="card-title m-0">
			<h3 class="fw-bolder m-0">{{ profileTitle }}</h3>
		</div>
	</div>
	<div class="card-body p-9">
		<ng-content select="[profileContent]"></ng-content>
	</div>
</div>
