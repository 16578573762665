import { Component, Input, OnInit } from '@angular/core'
import { ListSteps } from '../../models/steps'

@Component({
	selector: 'app-steps',
	templateUrl: './steps.component.html',
	styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() title?: string
	@Input() desc?: string
	@Input() listSteps?: ListSteps[] = []

	constructor() {}

	ngOnInit(): void {}
}
