import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fieldsDefault } from 'src/app/mocks/fields-default';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { ClientFormsComponent } from '../client-forms/client-forms.component';
import { Form } from 'src/app/models/form';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-card-forms',
  templateUrl: './card-forms.component.html',
  styleUrls: ['./card-forms.component.scss']
})
export class CardFormsComponent implements OnInit {
    @Output() forms = new EventEmitter()
    @Input() clientId: string = ''
    @Input() formId: string = ''
    @Input() liberateForms: boolean = false
    @Input() formsClient: Form[] = []
    fieldsAvailable: Form[] = []
    fieldsInForm: Form[] = [];
    show: boolean = true
    fieldsDraggableQntd: number = 0

    constructor(
        private modalDefault: NgbModal,
        private contractClientService: ContractClientService,
        private toast: ToastrService,
    ) {}

    ngOnInit(): void {
        if(this.formsClient.length) {
            this.showContent(1, () => {
                this.fieldsInForm = this.formsClient
                this.getQntdItemsDraggable()
            })
        } else {
            this.fieldsInForm = fieldsDefault
            this.getQntdItemsDraggable()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['clientId']) {
            this.onSubmit()
        }
        if (changes['liberateForms'] && this.fieldsInForm.length) {
            const forms = [{
                form: this.fieldsInForm,
                form_type: "patient_creation"
            }]
            this.forms.emit(forms)
        }
    }

    getQntdItemsDraggable() {
        const itemsDraggable = this.fieldsInForm.filter((item: any) => item.draggable)
        this.fieldsDraggableQntd = itemsDraggable.length
    }

    openModalForms(item?: Form, index?: number){
        const modalRef = this.modalDefault.open(ClientFormsComponent)
        if(item && item.label) {
            modalRef.componentInstance.clientForm = item
        }

        modalRef.componentInstance.callbackConfirmation = (itemEdited?: Form) => {
            if(!itemEdited) {
                setTimeout(() => {
                    const fieldsForm = modalRef.componentInstance.dataForm
                    this.fieldsAvailable = [ ...this.fieldsAvailable, fieldsForm ]
                    modalRef.close()
                }, 300)
                return
            }

            index = index || 0
            this.verifyItemExistInArray(item, index, itemEdited)
            modalRef.close()
        }
    }

    onChangeAvailable(list: any) {
        setTimeout(() => {
            list.map((item: Form) => {
                if(item.draggable) {
                    this.fieldsAvailable.map((field: Form, index: number) => {
                        if(field.label === item.label && field.json_name === item.json_name) {
                            this.fieldsAvailable.splice(index, 1)
                            this.verifyItemExistsInForm(item)
                        }
                    })
                }
            })
        }, 200);
    }

    onChangeform(list: any) {
        setTimeout(() => {
            const itemDraggable = list.filter((item: any) => item.draggable)
            if(itemDraggable.length > this.fieldsDraggableQntd) {
                list = list.slice(1, list.length)
                this.fieldsInForm = list
            }
        }, 300);
    }

    verifyItemExistsInForm(item: any) {
        this.showContent(1, () => {
            const someFieldExistInform = this.fieldsInForm.some((field: any) => {
                return field.label === item.label && field.json_name === item.json_name
            })

            if(!someFieldExistInform) {
                this.fieldsInForm = [ item, ...this.fieldsInForm ]
            }
        })
    }

    showContent(time: number, callback?: Function) {
        this.show = !this.show
        setTimeout(() => {
            this.show = !this.show
            if(callback) callback()
        }, time);
    }

    verifyItemExistInArray(item: any, index: number, itemEdited: any) {
        const isAvaliable = this.fieldsAvailable.find((el: any) => el.label === item.label)
        const isform = this.fieldsInForm.find((el: any) => el.label === item.label)

        if (isform) {
            this.fieldsInForm.splice(index, 1)
            this.fieldsInForm = [ itemEdited, ...this.fieldsInForm ]
        } else if (isAvaliable) {
            this.fieldsAvailable.splice(index, 1)
            this.fieldsAvailable = [ itemEdited, ...this.fieldsAvailable ]
        }
    }

    removeFlagDraggable() {
        return this.fieldsInForm.map((field: any) => {
            const { draggable, ...flags } = field
            return { ...flags }
        })
    }

    onSubmit() {
        if(this.clientId && this.fieldsInForm.length) {
            this.fieldsInForm = this.removeFlagDraggable()
            const self = this

            const data = {
                client_id: this.clientId,
                form: this.fieldsInForm,
                form_type: "patient_creation"
            }

            if(!this.formsClient?.length) {
                this.contractClientService.sendFormsClient(data, {
                    fnSuccess(data) {
                        self.toast.success('Formulário enviado com sucesso!')
                    }, fnError(err) {
                        console.warn(err)
                    }
                })
            } else if (this.formId) {
                this.contractClientService.editFormsClient(this.formId, data, {
                    fnSuccess(data) {
                        self.toast.success('Formulário atualizado com sucesso!')
                        self.clientId = ''
                        self.forms.emit()
                    }, fnError(err) {
                        console.warn(err)
                    }
                })
            } else {
                self.toast.error('Erro ao enviar o formulário!')
            }
        }
    }
}
