import { Component, Input, OnInit } from '@angular/core'
import { ColorsType } from '../../models/colorsType'

@Component({
	selector: 'app-title',
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
	@Input() title: string = ''
	@Input() tDetach: string = ''
	@Input() config: string = ''
	@Input() type?: ColorsType = 'success'

	constructor() {}

	ngOnInit(): void {}
}
