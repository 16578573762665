<div>
    <h3 class="text-center mb-10"> Editar formularios </h3>
    <app-card-forms
        *ngIf="show"
        [clientId]="clientIdSent"
        [formsClient]="dataForm"
        (forms)="getForms()"
        [formId]="formId"
    ></app-card-forms>

    <div class="d-flex justify-content-center mt-7">
        <button
            type="button"
            (click)="getIdClient()"
            class="btn btn-primary cursor-pointer btn-submit"
        >
            Salvar Alterações
        </button>
    </div>
</div>
