import { Component, OnInit } from '@angular/core'
import { ListMenu } from '../../models/list-menu'
import { AuthService } from '../../services/auth/auth.service'
import { Event, NavigationEnd, Route, Router } from '@angular/router'

@Component({
	selector: 'app-template-default',
	templateUrl: './template-default.component.html',
	styleUrls: ['./template-default.component.scss'],
})
export class TemplateDefaultComponent implements OnInit {
	titlePage: string = ''
	minimizeAside: boolean = false
	showMenu: boolean = false
	animating: boolean = false
	isMobile: boolean = true

	menuSystem: Array<ListMenu> = []
	menuProfile: Array<ListMenu> = []

	constructor(private authService: AuthService, private router: Router) {
		this.menuSystem = [
			{
				title: 'Geral',
				link: '/panel/users',
				icon: 'person',
				subItems: [
					{
						title: 'Lista de usuários',
						link: '/panel/users',
					},
					{
						title: 'Lista de empresas',
						link: '/panel/clientes',
					},
				],
			},
			{
				title: 'Componentes',
				link: '/panel/componentes',
				icon: 'utilities',
			},
			// {
			//     title: 'Empresas',
			//     link: '#',
			//     icon: '/assets/images/media/icons/duotone/Code/Compiling-white.svg',
			//     subItems: [
			//         {
			//             title: 'Lista',
			//             link: '/panel/companies',
			//         },
			//         {
			//             title: 'Criar',
			//             link: '/panel/companies/new',
			//         }
			//     ]
			// },
		]
		this.menuProfile = [
			{
				title: 'Meu perfil',
				link: '#',
				icon: '/assets/images/media/icons/duotone/Code/Compiling-white.svg',
				subItems: [
					{
						title: 'Editar perfil',
						link: '/panel/profile',
					},
					{
						title: 'Configurações',
						link: '/404',
					},
				],
			},
		]
	}

	ngOnInit(): void {
		this.initWindow()
		this.getTitlePage()
		this.watchTitlePage()
	}

	initWindow(evt?: any): void {
		this.isMobile = evt?.currentTarget?.innerWidth <= 992
	}

	toggleMinimizeAside(): void {
		this.minimizeAside = !this.minimizeAside

		if (this.minimizeAside) {
			this.animating = true

			setTimeout(() => {
				this.animating = false
			}, 250)
		}
	}

	toggleMenu(): void {
		this.showMenu = !this.showMenu
	}

	getYear(): string {
		const date = new Date()
		return JSON.stringify(date.getFullYear())
	}

	logout(): void {
		this.authService.logout()
		setTimeout(() => {
			window.location.href = '/'
		}, 1)
	}

	watchTitlePage(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.getTitlePage()
			}
		})
	}

	private getTitlePage() {
		const { config } = this.router
		let currentRoute: Route | undefined

		config.forEach((item) => {
			if (item.children?.length) {
				item.children.filter((subItem) => {
					if (
						`${item.path}/${subItem.path}` ===
						this.router.url.replace('/', '')
					) {
						currentRoute = subItem
					}
				})
			}
			if (item.path === this.router.url.replace('/', '')) {
				currentRoute = item
			}
		})
		if (currentRoute?.data) {
			this.titlePage = currentRoute?.data.title
		}
	}
}
