<div class="card card-xl-stretch mb-xl-8">
	<div class="card-header border-0 pt-5">
		<h3 class="card-title align-items-start flex-column">
			<span class="card-label fw-bolder text-dark">{{ title }}</span>
			<span class="text-muted mt-1 fw-bold fs-7">{{ subTitle }}</span>
		</h3>
		<app-menu-sub>
			<div>
				<div class="menu-item px-3">
					<a href="#" class="menu-link px-3">New Ticket</a>
				</div>
				<div class="menu-item px-3">
					<a href="#" class="menu-link px-3">New Customer</a>
				</div>
				<div class="menu-item px-3">
					<a href="#" class="menu-link px-3">New Contact</a>
				</div>
			</div>
		</app-menu-sub>
	</div>
	<div class="card-body pt-5">
		<div
			class="d-flex align-items-sm-center mb-7"
			*ngFor="let item of trends"
		>
			<div class="symbol symbol-50px me-5">
				<a [routerLink]="'/panel/cliente/' + item.id">
					<span class="symbol-label">
						<app-image
							[load]="true"
							[path]="item?.logo"
							[title]="item?.name"
						></app-image>
					</span>
				</a>
			</div>
			<div class="d-flex align-items-center flex-row-fluid flex-wrap">
				<div class="flex-grow-1 me-2">
					<a href="#" class="text-gray-800 fs-6 fw-bolder">{{
						item?.name
					}}</a>
					<span class="text-muted fw-bold d-block fs-7">
						<span class="text-gray-600 fs-7 fw-bolder"> Memed </span
						>:
						{{ item.usesMemed ? 'Sim' : 'Não' }}
					</span>
				</div>
				<span class="badge badge-light fw-bolder my-2">{{
					item?.value
				}}</span>
			</div>
		</div>
	</div>
</div>
