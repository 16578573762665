<div class="card card-xl-stretch">
    <div class="card-header align-items-center border-0 mt-4">
        <h3 class="card-title align-items-start flex-column">
            <span class="fw-bolder mb-2 text-dark">{{title}}</span>
            <span class="text-muted fw-bold fs-7">{{subTitle}}</span>
        </h3>
    </div>
    <div class="card-body pt-5">
        <div *ngIf="list.length" class="timeline-label">
            <div class="timeline-item" *ngFor="let item of list">
                <div class="timeline-label fw-bolder text-gray-800">{{item.date}}</div>
                <div class="timeline-badge">
                    <i class="fa fa-genderless text-{{item.type}} fs-1"></i>
                </div>
                <div class="timeline-content ps-3 {{item.isBold ? 'fw-bolder text-gray-800' : 'fw-mormal text-muted'}}">{{item.title}}</div>
            </div>
        </div>
        <app-empty *ngIf="!list.length"></app-empty>
    </div>
</div>
