<div class="form-check form-check-custom form-check-solid" [formGroup]='form'>
    <input
        type='checkbox'
        [id]="'c-' + id || name"
        [attr.name]='name'
        [disabled]='true'
        [formControlName]='name'
        [setValue]='inputModel'
        [value]='value'
        [checked]="checked"
        (change)='fnChangeModel($event)'
        class="form-check-input"
    />
    <label class="form-check-label" [for]="'c-' + id || name">
        {{labelName}}
        <ng-content></ng-content>
    </label>
    <div *ngIf='form.controls[name || ""]?.invalid &&
             (form.controls[name || ""]?.touched || form.controls[name || ""]?.dirty)'
         class="fv-plugins-message-container invalid-feedback"
    >
        <div [setFormControlError]='{objError: form.controls[name || ""].errors, control: labelName}'></div>
    </div>
</div>
