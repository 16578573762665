<app-loading-progress *ngIf="loading"></app-loading-progress>
<div class="card card-body py-5 px-5">
	<form
		action="#"
		autocomplete="off"
		(ngSubmit)="getContractId()"
		[formGroup]="form"
	>
		<div class="row">
			<div class="col-lg-6">
				<app-input
					id="name"
					name="name"
					typeInput="text"
					placeholder="Digite seu nome"
					labelName="Nome*"
					[form]="form"
					[inputModel]="user?.name"
				></app-input>
			</div>
			<div class="col-lg-6">
				<app-input
					id="s-nome"
					name="lastName"
					typeInput="text"
					placeholder="Digite seu sobrenome"
					labelName="Sobrenome*"
					[form]="form"
					[inputModel]="user?.lastName"
				></app-input>
			</div>
			<div class="col-lg-12">
				<app-input
					id="cpf"
					name="cpf"
					typeInput="text"
					placeholder="Digite seu cpf*"
					labelName="CPF*"
					[form]="form"
					[inputModel]="user?.cpf"
					inputMask="000.000.000-00"
				></app-input>
			</div>
			<div class="col-lg-12">
				<app-input
					id="email"
					name="email"
					typeInput="email"
					placeholder="Digite seu E-mail"
					labelName="E-mail*"
					[form]="form"
					[inputModel]="user?.email"
				></app-input>
			</div>
			<div class="col-lg-12">
				<app-input
					id="password"
					name="password"
					typeInput="password"
					placeholder="Senha"
					labelName="Senha*"
					[form]="form"
					[inputModel]="user?.password"
				></app-input>
			</div>
			<div class="col-lg-6">
				<app-input
					id="date"
					name="birthdate"
					typeInput="date"
					placeholder="EX.: 20/07/1985"
					labelName="Data de nascimento*"
					[form]="form"
					[inputModel]="user?.birthdate"
				></app-input>
			</div>
			<div class="col-lg-6">
				<app-select
					*ngIf="_reload"
					id="gender"
					name="gender"
					[options]="genreOptions"
					labelName="Gênero*"
					placeholder="M ou F"
					[form]="form"
					[inputModel]="user?.gender"
				></app-select>
			</div>
			<div class="col-lg-6">
				<label class="form-label" for="roles">Papeis*</label>
				<ng-select2
					width="100%"
					id="roles"
					[options]="options"
					[data]="listRoles"
					placeholder="Selecione um ou mais papel"
					(valueChanged)="changedSelectRoles($event)"
				>
				</ng-select2>
			</div>
			<div class="col-lg-6 mb-10">
				<label class="form-label" for="clients">Clientes*</label>
				<ng-select2
					width="100%"
					id="clients"
					[options]="options"
					[data]="listClients"
					placeholder="Selecione um ou mais cliente"
					(valueChanged)="changedSelectClients($event)"
				>
				</ng-select2>
			</div>
			<div class="col-lg-6" *ngIf="isDoctor">
				<app-input
					id="crmUf"
					name="crmUf"
					typeInput="text"
					placeholder="Digite seu CRM"
					labelName="CRM"
					[form]="form"
					[inputModel]="user?.crmUf"
					[inputMask]="'000000-AA'"
				></app-input>
			</div>
		</div>
		<div class="wrapper-button py-5">
			<button
				type="submit"
				class="btn btn-primary"
				[disabled]="
					form.invalid ||
					!listRolesSelected.length ||
					!listClientsSelected.length ||
					isSubmitting
				"
			>
				Criar usuário
			</button>
		</div>
	</form>
</div>
