import { ListHeader } from '../models/list-header'
import { selectType } from '../models/selectType'

export const clientListHeader: ListHeader[] = [
    {
		value: 'online',
		label: '',
		align: 'center',
	},
    {
		value: 'clients',
		label: 'Empresas',
		align: 'center',
		className: 'col-3',
	},
    {
		value: 'memed',
		label: 'Status Memed',
		align: 'center',
		className: 'col-4 align-center',
	},
    {
		value: 'employeesAmount',
		label: 'Qntd. de funcionários',
		align: 'center',
		className: 'col-4 align-center',
	},
]

export const inputTypeList: selectType[] = [
    { label: "Preencha o tipo de input", value: ''},
    { label: "botão", value: "button"},
    { label: "texto", value: "text"},
    { label: "checkbox", value: "checkbox"},
    { label: "data", value: "date"},
    { label: "email", value: "email"},
    { label: "arquivo", value: "file"},
    { label: "imagem", value: "image"},
    { label: "numero", value: "number"},
    { label: "senha", value: "password"},
    { label: "radio", value: "radio"},
    { label: "telefone", value: "tel"},
    { label: "url", value: "url"},
]
