<div class="bg-opacity" *ngIf="hasOpen" (click)="closeChat()"></div>
<div class="bg-body drawer drawer-end drawer-on" [class.active]="hasOpen">
	<div class="card w-100 rounded-0 border-0">
		<div class="card-header pe-5">
			<div class="card-title">
				<div class="d-flex justify-content-center flex-column me-3">
					<a
						href="#"
						class="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1"
						>{{ user?.name }} {{ user?.lastName }}</a
					>
					<div class="mb-0 lh-1">
						<span
							class="badge badge-success badge-circle w-10px h-10px me-1"
						></span>
						<span class="fs-7 fw-bold text-muted">Active</span>
					</div>
				</div>
			</div>
			<div class="card-toolbar">
				<app-menu-sub>
                    <div>
                        <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Ticket</a>
                        </div>
                        <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Customer</a>
                        </div>
                        <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">New Contact</a>
                        </div>
                    </div>
                </app-menu-sub>
				<div
					class="btn btn-sm btn-icon btn-active-light-primary"
					(click)="closeChat()"
				>
					<span class="svg-icon svg-icon-2">
						<app-svg name="close"></app-svg>
					</span>
				</div>
			</div>
		</div>
		<div class="card-body" id="kt_drawer_chat_messenger_body">
			<div class="scroll-y me-n5 pe-5" style="height: 685px">
				<div class="d-flex justify-content-start mb-10">
					<div class="d-flex flex-column align-items-start">
						<div class="d-flex align-items-center mb-2">
							<div class="symbol symbol-35px symbol-circle">
								<app-image
									path="https://picsum.photos/35/35"
									[title]="user?.name + ' ' + user?.lastName"
								></app-image>
							</div>
							<div class="ms-3">
								<a
									href="#"
									class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
									>{{ user?.name }} {{ user?.lastName }}</a
								>
								<span class="text-muted fs-7 mb-1">2 mins</span>
							</div>
						</div>
						<div
							class="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start"
							data-kt-element="message-text"
						>
							How likely are you to recommend our company to your
							friends and family ?
						</div>
					</div>
				</div>
				<div class="d-flex justify-content-end mb-10">
					<div class="d-flex flex-column align-items-end">
						<div class="d-flex align-items-center mb-2">
							<div class="me-3">
								<span class="text-muted fs-7 mb-1">5 mins</span>
								<a
									href="#"
									class="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1"
									>You</a
								>
							</div>
							<div class="symbol symbol-35px symbol-circle">
								<app-image
									path="https://picsum.photos/35/35"
									title="You"
								></app-image>
							</div>
						</div>
						<div
							class="p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end"
							data-kt-element="message-text"
						>
							Hey there, we’re just writing to let you know that
							you’ve been subscribed to a repository on GitHub.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer pt-4" id="kt_drawer_chat_messenger_footer">
			<textarea
				class="form-control form-control-flush mb-3"
				rows="1"
				data-kt-element="input"
				placeholder="Type a message"
			></textarea>
			<div class="d-flex flex-stack">
				<div class="d-flex align-items-center me-2">
					<button
						class="btn btn-sm btn-icon btn-active-light-primary me-1"
						type="button"
						data-bs-toggle="tooltip"
						title=""
						data-bs-original-title="Coming soon"
					>
						<i class="bi bi-paperclip fs-3"></i>
					</button>
					<button
						class="btn btn-sm btn-icon btn-active-light-primary me-1"
						type="button"
						data-bs-toggle="tooltip"
						title=""
						data-bs-original-title="Coming soon"
					>
						<i class="bi bi-upload fs-3"></i>
					</button>
				</div>
				<button
					class="btn btn-primary"
					type="button"
					data-kt-element="send"
				>
					Send
				</button>
			</div>
		</div>
	</div>
</div>
