import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UserService } from '../../../services/user/user.service'
import { Users } from '../../../models/users'
import { HelpersService } from '../../../services/helpers/helpers.service'
import { ListMenu } from '../../../models/list-menu'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Options } from 'select2'
import { Select2OptionData } from 'ng-select2'
import { listRolesMocks } from '../../../mocks/users'
import {
	HiddenLoading,
	ShowLoading,
} from '../../../state/loading/loading.action'
import { Store } from '@ngrx/store'
import { AppState } from '../../../state/app-state'
import { ToastrService } from 'ngx-toastr'

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	userId: string = ''
	user?: Users
	listMenu: ListMenu[] = []
	itemSelected: string = 'profile'
	loading: boolean = false
	form: FormGroup
	genreOptions: any[]
	public listRoles: Array<Select2OptionData> = []
	public listClients: Array<Select2OptionData> = []
	listRolesSelected: string[] = []
	listClientsSelected: string[] = []
	// @ts-ignore
	public options: Options
	cont: number = 0
	clientsContract: any[] = []

	public initialRoles?: any[] = []
	public initialClients?: any[] = []
	isSubmitting: boolean = false

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private store: Store<AppState>,
		private toast: ToastrService,
		private userService: UserService,
		public helpers: HelpersService,
	) {
		this.form = this.formBuilder.group({
			name: [this.user?.name, [Validators.required]],
			lastName: [this.user?.lastName, [Validators.required]],
			email: [this.user?.email, [Validators.required]],
			cpf: [this.user?.cpf, [Validators.required]],
			password: [this.user?.password, [Validators.required]],
			birthdate: [this.user?.birthdate, [Validators.required]],
			gender: [this.user?.gender, [Validators.required]],
		})

        this.route.params.subscribe(params => {
            if(params['slug'] == 'empresas') {
                this.itemSelected = 'empresas'
            }
        });

		this.genreOptions = [
			{
				value: 'M',
				label: 'Masculino',
			},
			{
				value: 'F',
				label: 'Feminino',
			},
		]

		this.listMenu = [
			{
				title: 'profile',
				link: '#',
			},
			{
				title: 'empresas',
				link: '#',
			},
		]
	}

	ngOnInit(): void {
		this.getProfile()
		this.listRoles = listRolesMocks

		this.options = {
			width: '300',
			multiple: true,
			tags: true,
		}
	}

	getProfile() {
		const id = this.route.snapshot.params.id
		const self = this
		this.loading = true

		if (id) {
			this.userService.getUser(id, {
				fnSuccess(response) {
					self.user = response
					self.initialClients = []

					self.loading = false
					self.initialRoles = self.user?.roles

					self.user?.clients?.forEach((item: any) => {
						self.initialClients?.push(item.id)
					})
				},
				fnError(responseError) {
					console.warn(responseError)
					self.loading = false
				},
			})
			this.userId = id
		}
	}

	onUpdate(): void {
		const self = this
		self.store.dispatch(ShowLoading())
		this.isSubmitting = true

		if (self.form?.valid) {
			const documents = [
				{
					document_type: 'cpf',
					document_number: this.form.value.cpf,
				},
			]
			const data = {
				name: this.form.value.name,
				last_name: this.form.value.lastName,
				email: this.form.value.email,
				documents: documents,
				password: this.form.value.password,
				birthdate: this.form.value.birthdate,
				gender: this.form.value.gender,
			}
			if (self.user?.id) {
				self.userService.updateUser(self.user?.id, data, {
					fnSuccess() {
						self.isSubmitting = false
						self.store.dispatch(HiddenLoading())
						self.toast.success('Dados atualizados com sucesso')
						setTimeout(() => {
							window.location.href = '/'
						}, 1000)
					},
					fnError(error) {
						self.store.dispatch(HiddenLoading())
						self.toast.error(error.error.detail)
						self.isSubmitting = false
					},
				})
			}
		} else {
			self.toast.error('Formulário inválido!')
			self.store.dispatch(HiddenLoading())
		}
	}

	setIdActiveItem(item: ListMenu): void {
		this.itemSelected = item.title
	}

	changedSelectRoles(e: any) {
		this.listRolesSelected = e
	}

	changedSelectClients(e: any) {
		this.listClientsSelected = e
	}
}
