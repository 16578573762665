<div class="modal-body">
    <h5 *ngIf='title'>{{title}}</h5>
    <p *ngIf='desc'>{{desc}}</p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-white btn-active-light-primary me-2"
        (click)="close()"
    >
        Cancelar
    </button>
    <button type="button" class="btn btn-xs btn-primary" (click)="confirmation()">
        Confirmar
    </button>
</div>
