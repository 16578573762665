import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { fieldsDefault } from 'src/app/mocks/fields-default';
import { Form } from 'src/app/models/form';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
    dataForm: Form[] = []
    clientId: string = ''
    clientIdSent: string = ''
    formId: string = ''
    show: boolean = false

    constructor(
        private formsService: ContractClientService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.clientId = this.route.snapshot.params.id
        this.getForms()
    }

    setFormDefaults() {
        this.dataForm = fieldsDefault
        this.reloadContentForms()
    }

    getForms() {
        const self = this
        this.formsService.getFormsClient(this.clientId, {
            fnSuccess(data) {
                const fieldValid = data.find((item: any) => typeof item.form === 'object')
                const { form, id } = fieldValid
                self.formId = id

                if(!data || !fieldValid) {
                    self.setFormDefaults()
                    return
                }
                self.addingDraggableItemsDefault(form)
            }, fnError(err) { console.warn(err) }
        })
    }

    addingDraggableItemsDefault(forms: Form[]) {
        const formsFiltreds: Form[] = []
        const formNames: string[] = []

        forms.forEach((form: Form) => {
            const { json_name, label } = form
            const fieldIsDefault = fieldsDefault.find((field: Form) => field.json_name === json_name && field.label === label)

            if(fieldIsDefault && !formNames.includes(label)) {
                formNames.push(label)
                const data = { ...form, draggable: true }
                formsFiltreds.push(data)
            } else {
                formNames.push(label)
                formsFiltreds.push(form)
            }
        })

        this.reloadContentForms()
        this.dataForm = formsFiltreds
    }

    reloadContentForms() {
        this.show = false
        setTimeout(() => { this.show = true }, 1)
    }

    getIdClient() {
        this.clientIdSent = this.clientId

        setTimeout(() => { this.clientIdSent = '' }, 200);
    }
}
