<div class="card mb-5 mb-xl-8">
	<div class="card-body pb-0">
		<div class="d-flex align-items-center mb-3">
			<div class="d-flex align-items-center flex-grow-1">
				<div class="symbol symbol-45px me-5 w-45px">
					<app-image
						[load]="true"
						[path]="
							'https://i.picsum.photos/id/472/45/45.jpg?hmac=9V59NALvPPhi9F8tK5nKlVjX68mbluDRSJUVvCqDTWk'
						"
						title="Carles Nilson"
					></app-image>
				</div>
				<div class="d-flex flex-column">
					<a
						href="#"
						class="text-gray-900 text-hover-primary fs-6 fw-bolder"
						>Carles Nilson</a
					>
					<span class="text-gray-400 fw-bold"
						>Yestarday at 5:06 PM</span
					>
				</div>
			</div>

			<app-menu-sub>
                <div>
                    <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Ticket</a>
                    </div>
                    <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Customer</a>
                    </div>
                    <div class="menu-item px-3">
                        <a href="#" class="menu-link px-3">New Contact</a>
                    </div>
                </div>
            </app-menu-sub>
		</div>
		<div class="mb-7">
			<div class="text-gray-800 mb-5">
				Outlines keep you honest. They stop you from indulging in poorly
				thought-out metaphors about driving and keep you focused on the
				overall structure of your post
			</div>
			<div class="d-flex align-items-center mb-5">
				<a
					href="#"
					class="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
				>
					<span class="svg-icon svg-icon-3">
						<app-svg name="balloon"></app-svg>
					</span>
					2
				</a>
				<a
					href="#"
					class="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2"
				>
					<span class="svg-icon svg-icon-2">
						<app-svg name="heart"></app-svg>
					</span>
					2
				</a>
			</div>
		</div>
		<div class="mb-7 ps-10">
			<div class="d-flex mb-5">
				<div class="symbol symbol-45px me-5 w-45px">
					<app-image
						[load]="true"
						[path]="
							'https://i.picsum.photos/id/611/45/45.jpg?hmac=pxj2p5i3d5Wab2Tja55KxUGrirh2wqnRsqQJSOW5mF4'
						"
						title="Alice Danchik"
					></app-image>
				</div>
				<div class="d-flex flex-column flex-row-fluid">
					<div class="d-flex align-items-center flex-wrap mb-1">
						<a
							href="#"
							class="text-gray-800 text-hover-primary fw-bolder me-2"
							>Alice Danchik</a
						>
						<span class="text-gray-400 fw-bold fs-7">1 day</span>
						<!--						<a-->
						<!--							href="#"-->
						<!--							class="ms-auto text-gray-400 text-hover-primary fw-bold fs-7"-->
						<!--							>Reply</a-->
						<!--						>-->
					</div>
					<span class="text-gray-800 fs-7 fw-normal pt-1"
						>Long before you sit dow to put digital pen to paper you
						need to make sure you have to sit down and write.</span
					>
				</div>
			</div>
			<div class="d-flex">
				<div class="symbol symbol-45px me-5 w-45px">
					<app-image
						[load]="true"
						[path]="
							'https://i.picsum.photos/id/611/45/45.jpg?hmac=pxj2p5i3d5Wab2Tja55KxUGrirh2wqnRsqQJSOW5mF4'
						"
						title="Alice Danchik"
					></app-image>
				</div>
				<div class="d-flex flex-column flex-row-fluid">
					<div class="d-flex align-items-center flex-wrap mb-1">
						<a
							href="#"
							class="text-gray-800 text-hover-primary fw-bolder me-2"
							>Harris Bold</a
						>
						<span class="text-gray-400 fw-bold fs-7">2 days</span>
						<!--						<a-->
						<!--							href="#"-->
						<!--							class="ms-auto text-gray-400 text-hover-primary fw-bold fs-7"-->
						<!--							>Reply</a-->
						<!--						>-->
					</div>
					<span class="text-gray-800 fs-7 fw-normal pt-1"
						>Outlines keep you honest. They stop you from indulging
						in poorly</span
					>
				</div>
			</div>
		</div>
		<div class="separator mb-4"></div>
		<form class="position-relative mb-6">
			<textarea
				class="form-control border-0 p-0 pe-10 resize-none min-h-25px"
				rows="1"
				placeholder="Reply.."
				style="
					overflow: hidden;
					overflow-wrap: break-word;
					height: 25px;
				"
			></textarea>
		</form>
	</div>
</div>
