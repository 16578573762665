import { device } from '../models/device'

export const protocolsDefault: device[] = [
    {
        capturedBy: "mobile",
        exams: [
            "52a9e0b8-ae7c-467f-ba5b-23705815ba4a",
            "fc2e381e-8aae-43fd-9921-db036ffd8db2",
            "f29fc839-953d-458a-a8e0-aee77fb80b6a",
            "9d487125-bff4-4126-93b3-0643db139bad",
            "0e5b768d-d724-4417-8cab-b9bb2389cef8",
            "92c9468d-c29e-4cfd-9f28-cf91a9e7b4a1",
            "6ee2713c-a863-4c7d-9d19-bfb2ad635295"
        ],
        device: "22718d80-93b0-4696-866c-6ca362d43b90"
    },
    {
        capturedBy: "cabin",
        exams: ["29236600-480f-4769-82a7-c80f0fc9fdff"],
        device: "22718d80-93b0-4696-866c-6ca362d43b90"
    },
    {
        capturedBy: "mobile",
        exams: ["aabc3ebd-3ade-415f-8a53-d84432d1249b", "fc2e381e-8aae-43fd-9921-db036ffd8db2"],
        device: "cbee95ab-a317-41ac-8217-6d82147f1df0"
    },
    {
        capturedBy: "cabin",
        exams: [
            "a9a80154-62fd-42e3-a672-786cc57adad4",
            "07aa92f7-daaf-4d6e-9619-4add21c89e5b",
            "887b5554-6d01-48cf-9d16-8e562a8d6779",
            "1daeb770-67b7-4ec9-a104-958454edc459"
        ],
        device: "c988b358-a8c3-47f0-af94-6e036bf80272"
    },
]
