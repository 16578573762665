import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-modal-default',
	templateUrl: './modal-default.component.html',
	styleUrls: ['./modal-default.component.scss'],
})
export class ModalDefaultComponent implements OnInit {
    @Input() callbackConfirmation?: Function
    @Input() title?: string
    @Input() desc?: string

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	confirmation() {
        if (this.callbackConfirmation) this.callbackConfirmation()
        this.close()
	}
}
