import {Component, Input, OnInit} from '@angular/core';
import {TimelineItem} from "../../models/timeline";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
    @Input() title: string = ''
    @Input() subTitle: string = ''
    @Input() list: TimelineItem[] = []

  constructor() { }

  ngOnInit(): void {
  }

}
