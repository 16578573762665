<h2 class="fs-2tx fw-bolder mb-8" [class]="config">
	<span *ngIf="title">
		{{ title }}
	</span>
	<span class="d-inline-block position-relative ms-2" *ngIf="tDetach">
		<span class="d-inline-block mb-2">{{ tDetach }}</span>
		<span
			class="d-inline-block position-absolute h-8px bottom-0 end-0 start-0 bg-{{
				type
			}} translate rounded"
		></span>
	</span>
	<ng-content></ng-content>
</h2>
