import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AuthService } from '../../services/auth/auth.service'
import { AppState } from '../../state/app-state'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ShowLoading, HiddenLoading } from '../../state/loading/loading.action'

import { ToastrService } from 'ngx-toastr'
import { StatsService } from '../../services/stats/stats.service'

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	form: FormGroup

	constructor(
		private store: Store<AppState>,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private toast: ToastrService,
		private statsService: StatsService
	) {
		this.form = this.formBuilder.group({
            email: [null, [Validators.required, Validators.email]],
			password: [null, Validators.required],
		})
	}

	ngOnInit(): void {
	}

	onSubmit(): void {
		const self = this
		if (self.form.valid) {
			// self.statsService.stats('login_doctor', 'start')
			self.store.dispatch(ShowLoading())
			const { email, password } = self.form.value

			this.authService.login({
				payload: {
					email,
					password,
				},
				fnSuccess() {
					self.form.reset()
					self.authService.redirectAuth()
					// self.statsService.stats('login_doctor', 'end')
				},
				fnError(responseError) {
					if (
						responseError.status >= 400 &&
						responseError.status < 500
					) {
						self.toast.error('Login ou senha incorreto', 'Erro!')
					} else {
						self.toast.error(
							`Status: ${responseError.status}`,
							'Server Error!'
						)
					}
					console.warn(responseError)
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			})
		}
	}
}
