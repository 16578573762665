import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientInfo } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client/client.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { Options } from 'select2'
import { Select2OptionData } from 'ng-select2'
// @ts-ignore
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { selectType } from 'src/app/models/selectType';

@Component({
  selector: 'app-form-client',
  templateUrl: './form-client.component.html',
  styleUrls: ['./form-client.component.scss']
})
export class FormClientComponent implements OnInit {
    @Input() client?: ClientInfo
    @Input() contract: boolean = false
    @Output() sentProtocol = new EventEmitter()
    form?: FormGroup
    statusMemed: selectType[]
    contractTypesList: selectType[] = []
    listSpecialties: Array<Select2OptionData> = []
    options: Options
    clientIdSent: string = ''
    submitProtocol: boolean = false
    submiting: boolean = false
    loading: boolean = false
    dataForms: any[] = []
    liberateForms: boolean = false

    public dataCkEditor: any = ''
    Editor = ClassicEditor
    configEditor: any

    constructor(
        private formBuilder: FormBuilder,
        private toast: ToastrService,
        private clientService: ClientService,
        private helpers: HelpersService,
        private contractClientService: ContractClientService,
    ) {
        this.typeForm()

        this.options = {
            width: '300',
            multiple: true,
        }

        this.configEditor = {
			removePlugins: [
				'EasyImage',
				'Anchor',
			],
		}

        this.statusMemed = [
            {
                value: 'true',
                label: 'Sim',
            },
            {
                value: 'false',
                label: 'Não',
            },
        ]
    }

    ngOnInit(): void {
        this.getContractTypes()
        if(!this.client) {
            this.getSpecialties()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['contract'] || changes['client']) {
            this.typeForm()
            return
        }
    }

    typeForm() {
        if(this.contract) {
            this.form = this.formBuilder.group(this.helpers.groupFormDinamic('contrato'))
            return
        }
        if (this.client) {
            this.form = this.formBuilder.group(this.helpers.groupFormDinamic('editar', this.client))
            return
        }
        this.form = this.formBuilder.group(this.helpers.groupFormDinamic('geral', this.client))
    }

    loadingButtonSubmit() {
        this.submiting = !this.submiting
        this.liberateForms = !this.liberateForms
    }

    submitingProtocol(e: any) {
        if(e.error) {
            this.submiting = false
        } else {
            this.submitProtocol = e.submit
        }
    }

    getContractTypes() {
        const self = this
        this.contractClientService.getContractTypes(false, {
            fnSuccess(data) {
                self.contractTypesList = data.map(({ name, id }: any) => {
                    return {
                        label: name,
                        value: id
                    }
                })
            }, fnError(err) { console.warn('ERROR ', err) }
        })
    }

    createClient(data: any) {
        const self = this
        data = this.helpers.converterCamelCaseToSnake(data)

        if (!this.client) {
            this.clientService.createClient(data, {
                fnSuccess({ id }) {
                    self.toast.success('Formulário enviádo com sucesso!')
                    self.submitContract(id)
                    self.clientIdSent = id
                }, fnError(error) {
                    if (error.status == 422) {
                        self.toast.error('Preencha todas as informações necessarias ou verfique se as informações preenchidas já estão em uso')
                    }
                    self.submiting = !self.submiting
                },
            })
        }
    }

    submitContract(id: any) {
        const self = this
        const { content, contractType, specialties } = this.form?.value
        const context_id = id || this.client?.id

        const body = {
            content,
            context_id,
            contract_type_id: contractType,
            is_active: 'true'
        }

        if (id) {
            this.sendSpecialtiesClient(id, specialties)
            this.contractClientService.sendContract(body, {
                fnSuccess(data) {
                    self.toast.success('Contrato adicionado com sucesso!')
                    if(self.contract) self.sentProtocol.emit(body)
                }, fnError(e) {
                    self.submiting = !self.submiting
                    self.toast.error(`Error de contrato: ${e}`)
                }
            })
            return
       }
       this.toast.error('Preencha todas as informações necessárias para finalizar o contrato')
    }

    updateClient(id: string, data: any) {
        const self = this
        data = this.helpers.converterCamelCaseToSnake(data)
        this.clientService.updateClient(id, data, {
            fnSuccess(response) {
                self.toast.success('Informações alteradas com sucesso!')
                self.helpers.reloadPage(1000)
            },
            fnError(err) {
                self.toast.error(`Error: ${err}`)
            },
        })
    }

    getSpecialties() {
        const self = this
        this.loading = true
        this.clientService.getSpecialtiesClient(false, {
            fnSuccess(data) {
                self.listSpecialties = data
                self.loading = false
            }, fnError(e) {
                self.loading = false
                console.warn('ERROR', e)
            }
        })
    }

    sendSpecialtiesClient(id: string, data: any) {
        data = { 'specialties': data }
        const self = this
        if (id) {
            this.clientService.sendSpecialtiesClient(id, data, {
                fnSuccess(data) {
                    self.toast.success('Especialidade adicionada com sucesso')
                },
                fnError(e) {
                    self.submiting = !self.submiting
                    self.toast.error(`Error no envio da especialidade ${e}`)
                }
            })
        }
    }

    onSubmit(forms?: any): void {
        if (this.contract && this.client || this.form?.valid) {
            if (this.contract) {
                this.submitContract(this.client?.id)
                return
            }

            const { appointmentsExpiration, name, logo, usesMemed, externalConference } = this.form?.value
            const conferenceLink = externalConference ? externalConference.trim() : ''

            const data: any = {
                appointmentsExpiration,
                logo: logo || '/assets/images/error-image.png',
                name,
                usesMemed,
                externalConference: conferenceLink,
            }

            if(forms) data['forms'] = forms

            if(this.client) {
                this.updateClient(this.client.id, data)
            } else {
                this.createClient(data)
            }
            return
        }
        this.toast.error('Formulário inválido!')
    }
}
