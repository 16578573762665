import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { device, deviceComplete, deviceSingle } from 'src/app/models/device';
import { ContractClientService } from 'src/app/services/client/contract-client.service';

@Component({
  selector: 'app-modal-preferrers',
  templateUrl: './modal-preferrers.component.html',
  styleUrls: ['./modal-preferrers.component.scss']
})
export class ModalPreferrersComponent implements OnInit {
    @Input() callbackConfirmation: Function = () => {}
    listDevicesAndExams: device[] = []
    itemsSents: deviceSingle[] = []
    devicesList: { device: string, capturedBy: string, id: string }[] = []
    devicesByExam: deviceComplete[] = []
    examslist: { name: string, id: string }[] = []
    examSelected?: { name: string, id: string }
    devicesSelected: deviceSingle[] = []
    deviceSelected?: deviceSingle
    devicesPreferrers: deviceSingle[] = []

    constructor(
        private contractService: ContractClientService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
        this.getExams()
        this.getDevicesList()

        if(this.itemsSents.length) {
            this.itemsSents.forEach((item) => {
                this.devicesSelected?.push(item)
            })
        }
    }

    getDevicesList() {
        const self = this

        const items = this.listDevicesAndExams
            .map((deviceAndExam: device) => {
                return {
                    device: deviceAndExam.device,
                    capturedBy: deviceAndExam.capturedBy,
                }
            }
        )

        items.forEach((item: { capturedBy: string, device: string }) => {
            const { device, capturedBy } = item
            this.getDeviceModelsId(device, (data: any) => {
                self.devicesList.push({
                    device: data.name,
                    capturedBy,
                    id: device
                })
            })
        })
    }

    getDeviceModelsId(device: string, callback?: Function) {
        this.contractService.getDeviceModelId(device, {
            fnSuccess(data) {
                if(callback) callback(data)
            }, fnError(e) {
                console.warn("error", e)
            }
        })
    }

    getExams() {
        const self = this
        const examsSents: any[] = []
        const controlGets: any = {}

        this.listDevicesAndExams.forEach(({ exams }) => {
            if(exams.length) {
                exams.forEach((exam: string, index: number) => {
                    if(!examsSents.includes(exam)) {
                        examsSents.push(exam)
                        controlGets[exam] = 1
                    } else if(examsSents.includes(exam) && controlGets[exam] === 1) {
                        controlGets[exam]++
                        this.contractService.getExamsDefinitionId(exam, {
                            fnSuccess(data) {
                                const { name } = data
                                self.examslist.push({
                                    name, id: exam
                                })
                            }, fnError(err) {
                                console.warn(err)
                            }
                        })
                    }
                })
            }
        })
    }

    getDeviceByExam(examInfos: { name: string, id: string }) {
        const { name, id } = examInfos
        const devices: any[] = []
        this.devicesByExam = []

        const selectedDevice = this.devicesSelected?.find((selected) => {
            return selected.exam === id
        })

        if(selectedDevice) {
            this.deviceSelected = selectedDevice
        }

        this.examSelected = { name, id }

        this.listDevicesAndExams.map((item: device) => {
            const examExists = item.exams.filter((exam: string) => exam === id)
            if(examExists.length) {
                const deviceCurrent = this.devicesList.find((device) => {
                    return item.device === device.id
                })

                devices.push({
                    exam: examExists[0],
                    device: deviceCurrent?.device,
                    device_id: deviceCurrent?.id,
                    capturedBy: item.capturedBy,
                })
            }
        })

        if(devices.length > 1) {
            this.devicesByExam = devices
        }
    }

    setDeviceOfExam(device: deviceComplete) {
        this.deviceSelected = {
            device: device.device_id,
            capturedBy: device.capturedBy,
            exam: device.exam
        }

        this.devicesSelected?.forEach((selected, index) => {
            if(selected.exam === device.exam && this.deviceSelected) {
                if(selected.device !== device.device){
                    this.devicesSelected[index] = this.deviceSelected
                } else {
                    this.devicesSelected.push(this.deviceSelected)
                }
            } else if(this.deviceSelected) {
                const examExists = this.devicesSelected.find(({ exam }) => exam === device.exam)
                if(!examExists) {
                    this.devicesSelected.push(this.deviceSelected)
                }
            }
        })

        if(!this.devicesSelected.length) {
            this.devicesSelected.push(this.deviceSelected)
        }
    }

    getItemActived(device: deviceComplete) {
        const deviceSelected = this.deviceSelected?.exam === device.exam &&
            this.deviceSelected?.device === device.device_id && this.deviceSelected?.capturedBy === device.capturedBy

        const deviceClicked = device.device_id == this.deviceSelected?.device &&
            device.capturedBy == this.deviceSelected?.capturedBy &&
            device.exam == this.deviceSelected?.exam

        return deviceClicked || deviceSelected
    }

    close() {
		this.activeModal.close()
	}

    confirmation() {
        this.callbackConfirmation(this.devicesSelected)
	}
}
