<div class="card card-xl-stretch mb-xl-8">
    <div class="card-header border-0">
        <h3 class="card-title fw-bolder text-dark">{{title}}</h3>
    </div>
    <div class="card-body pt-2">
        <div class="d-flex align-items-center mb-8" *ngFor="let item of list">
            <span class="bullet bullet-vertical h-40px bg-{{item.type}}"></span>
            <div class="form-check form-check-custom form-check-solid mx-5">
                <input class="form-check-input" type="checkbox" [checked]="item.isFinalized" />
            </div>
            <div class="flex-grow-1">
                <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6">{{item.title}}</a>
                <span class="text-muted fw-bold d-block">{{item.date}}</span>
            </div>
            <span *ngIf="item.tag?.title" class="badge badge-light-{{item.tag?.type}} fs-8 fw-bolder">{{item.tag?.title}}</span>
        </div>
    </div>
</div>
