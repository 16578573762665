<figure [class.effect-scale]="effectScale">
	<img
		*ngIf="path"
		[defaultImage]="load ? '../../assets/images/placeholder.gif' : ''"
		errorImage="../../assets/images/error-image.png"
		[lazyLoad]="path"
		[alt]="title"
		[title]="title"
	/>
</figure>
