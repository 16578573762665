import { Component, Input, OnInit } from '@angular/core'
import { User } from '../../models/user'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	constructor() {}
	hasOpenChat: boolean = false
	bulletNewMessage: boolean = true
	@Input() profile?: User

	ngOnInit(): void {}

	openChat() {
		this.bulletNewMessage = false
		this.hasOpenChat = !this.hasOpenChat
	}
}
