import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-menu-sub',
	templateUrl: './menu-sub.component.html',
	styleUrls: ['./menu-sub.component.scss'],
})
export class MenuSubComponent implements OnInit {
	active: boolean = false
	title: string = 'Ações'

	constructor() {}

	ngOnInit(): void {}

	activeMenuSup() {
		this.active = !this.active
	}
}
