import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientInfo } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client/client.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ItemMenu } from 'src/app/models/list-menu';
import { Select2OptionData } from 'ng-select2';

@Component({
    selector: 'app-profile-client',
    templateUrl: './profile-client.component.html',
    styleUrls: ['./profile-client.component.scss']
})
export class ProfileClientComponent implements OnInit {
    client?: ClientInfo
    itemSelected: string = 'editar'
    listMenu: ItemMenu[]
    id: string = ''
    specialsClient: Array<Select2OptionData> = []
    specialtiesItems: Array<Select2OptionData> = []

    constructor(
        private route: ActivatedRoute,
        private clientService: ClientService,
        public helpers: HelpersService,
    ) {
        this.listMenu = [
            {
                title: 'editar',
                icon: '',
                link: ''
            },
            {
                title: 'contratos',
                icon: '',
                link: ''
            },
            {
                title: 'especialidades',
                icon: '',
                link: ''
            },
            {
                title: 'protocolos',
                icon: '',
                link: ''
            },
            {
                title: 'formularios',
                icon: '',
                link: ''
            },
        ]
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.params.id
        this.getClient()
        this.getSpecialties(this.id)
        this.getSpecialties()
    }

    setIdActiveItem(item: ItemMenu): void {
        this.itemSelected = item.title
    }

    getSpecialties(id?: string) {
        const self = this
        this.clientService.getSpecialtiesClient(id || false, {
            fnSuccess(data) {
                if (id) {
                    self.specialsClient = data
                    return
                }
                self.specialtiesItems = data
            }, fnError(e) { console.warn('ERROR', e) }
        })
    }

    getClient() {
        const self = this
        if (this.id) {
            this.clientService.getClient(this.id, {
                fnSuccess(response) {
                    self.client = response
                },
                fnError(err) { console.warn(err) },
            })
        }
    }
}
