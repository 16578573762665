import { TimelineItem } from '../models/timeline'
import { ListTrends } from '../models/trends'
import { TodoItem } from '../models/todo'

export const timelineItemsMock: Array<TimelineItem> = [
	{
		title: 'Apenas um teste',
		date: '10/10/2022 ás 08:20',
		type: 'primary',
	},
	{
		title: 'Apenas um teste 2',
		date: '10/11/2022 ás 08:20',
		type: 'warning',
		isBold: true,
	},
	{
		title: 'Apenas um teste 3',
		date: '09/10/2022 ás 08:20',
		type: 'success',
	},
	{
		title: 'Apenas um teste 4',
		date: '10/10/2021 ás 08:20',
		type: 'danger',
		isBold: true,
	},
]

export const listTodoMock: Array<TodoItem> = [
	{
		title: 'Apenas um teste',
		date: '10/10/2022 ás 08:20',
		type: 'warning',
		isFinalized: true,
		tag: {
			title: 'Novo',
			type: 'primary',
		},
	},
	{
		title: 'Apenas um teste 2',
		date: '10/10/2022 ás 08:20',
		type: 'success',
		isFinalized: false,
		tag: {
			title: 'Novo',
			type: 'primary',
		},
	},
	{
		title: 'Apenas um teste 3',
		date: '10/10/2022 ás 08:20',
		type: 'warning',
		isFinalized: true,
		tag: {
			title: 'Novo',
			type: 'warning',
		},
	},
	{
		title: 'Apenas um teste 4',
		date: '10/10/2022 ás 08:20',
		type: 'danger',
		isFinalized: false,
		tag: {
			title: 'Novo',
			type: 'success',
		},
	},
]

export const trendsMock: Array<ListTrends> = [
	{
		logo: 'https://picsum.photos/25/25',
		name: 'Apenas um teste',
		locale: 'warning',
		value: '+90',
	},
	{
		logo: 'https://picsum.photos/26/26',
		name: 'Apenas um teste',
		locale: 'warning',
		value: '-56',
	},
]
export const listProgressMock = [
	{
		value: 60,
		title: 'success',
		subTitle: 'Test 1',
		type: 'success',
	},
	{
		value: 47,
		title: 'warning',
		subTitle: 'Test 1',
		type: 'warning',
	},
	{
		value: 20,
		title: 'danger',
		subTitle: 'Test 1',
		type: 'danger',
	},
	{
		value: 99,
		title: 'primary',
		subTitle: 'Test 1',
		type: 'primary',
	},
]
