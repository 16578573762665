<div class="mb-10" [formGroup]='form'>
    <label *ngIf="labelName" [for]="'i-' + id" class="form-label">{{labelName}}</label>
    <input
        [id]="'i-' + id"
        [type]="!showPass ? typeInput : 'text'"
        [attr.name]='name'
        [formControlName]='name'
        [placeholder]='placeholder'
        [setValue]='inputModel'
        [mask]='inputMask'
        [class.disabled]="disabled"
        [class]='"form-control form-control-solid " + (form.controls[name || ""].invalid &&
             (form.controls[name || ""].touched || form.controls[name || ""].dirty) ? "is-invalid" : "")'
        maxlength='50'
    />
    <span *ngIf='callout' class='callout'>{{ callout }}</span>
    <div *ngIf='form.controls[name || ""]?.invalid &&
            (form.controls[name || ""]?.touched || form.controls[name || ""]?.dirty)'
         class="fv-plugins-message-container invalid-feedback"
    >
        <div [setFormControlError]='{objError: form.controls[name || ""].errors, control: labelName}'>
        </div>
    </div>
</div>
