import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HelpersService } from '../helpers/helpers.service'
import { environment } from '../../../environments/environment'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { AuthService } from '../auth/auth.service'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(
		private http: HttpClient,
		private helpers: HelpersService,
		private store: Store<AppState>,
		private authService: AuthService
	) {}

	getAllUsers(callback: Callbacks): void {
		const self = this
		const params = {
			per_page: 200,
		}
		this.http
			.get<any>(`${environment.api}/users`, { params })
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					let { items } = data

					items = items.map((item: any) => {
						item = self.helpers.converterSnakeToCamelCase(item)

						const clients = item.clients.map((client: any) => {
							return self.helpers.converterSnakeToCamelCase(
								client
							)
						})

						return {
							...item,
							clients,
						}
					})

					data = {
						...data,
						items,
					}
					// console.log('SERVICE GET USERS ', data)
					callback.fnSuccess(data)
				},
				(err) => {
					callback.fnError(err)
					console.warn(err)
				}
			)
	}

	getUser(id: string, callback?: Callbacks): void {
		const self = this
		this.http
			.get<any>(`${environment.api}/users/${id}`)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					const clients = data.clients.map((item: any) => {
						item = self.helpers.converterSnakeToCamelCase(item)
						return item
					})

					data = {
						...data,
						clients,
					}
					// console.log(
					// 	'SERVICE GET USERS ',
					// 	self.helpers.converterSnakeToCamelCase(data)
					// )

					callback?.fnSuccess(
						self.helpers.converterSnakeToCamelCase(data)
					)
				},
				(err) => {
					callback?.fnError(err)
					console.warn(err)
				}
			)
	}

	createUser(data: any, callback?: Callbacks) {
		this.http
			.post(`${environment.api}/users`, data)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data: any) => {
                    // console.log('success create user =>',data)
					callback?.fnSuccess()
				},
				(err) => {
					// console.warn('error create user =>', err)
					callback?.fnError(err)
				}
			)
	}

	updateUser(id: string, data: any, callback?: Callbacks) {
		this.http
			.patch(`${environment.api}/users/${id}`, data)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data: any) => {
					callback?.fnSuccess()
				},
				(err) => {
					console.warn(err)
					callback?.fnError(err)
				}
			)
	}

	postFileProfile(
		fileToUpload: File,
        isClient?: boolean,
		id?: string,
		callback?: Callbacks
	): void {
		const user = this.authService.getUserLocale()
		const token = this.authService.getToken()
		const formData: FormData = new FormData()

		const file = this.DataURIToBlob(fileToUpload)
		formData.append('profile_picture', file, 'image.jpg')

        let url = `${environment.api}/users/${id}/profile_picture`

        if (isClient) url = `${environment.api}/clients/${id}/profile_picture`

		this.http
			.patch<any>(
				url,
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callback?.fnSuccess(data)
				},
				(err) => {
					callback?.fnError(err)
				}
			)
	}

	deleteUser(body: any, callback?: Function): void {
		const { id } = body

		this.http
			.delete<any>(`${environment.api}/users/${body}`)
			.pipe(
				finalize(() => {
					// if (body.fnFinalized) body.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					body.fnSuccess(data)
					if (callback) callback()
				},
				(err) => {
					console.warn(err)
					body.fnError(err)
				}
			)
	}

	DataURIToBlob(dataURI: any) {
		const splitDataURI = dataURI.split(',')
		const byteString =
			splitDataURI[0].indexOf('base64') >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1])
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

		const ia = new Uint8Array(byteString.length)
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i)

		return new Blob([ia], { type: mimeString })
	}
}
