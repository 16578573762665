<div class="forms" *ngIf="display">
    <h2 class="text-center mb-6 mt-6"> Campo do formulario </h2>
    <form
        action="#"
        autocomplete="off"
        [formGroup]="form"
    >
        <div class="row">
            <div class="col-lg-12">
                <app-input
                    id="label"
                    name="label"
                    placeholder="ex: CPF"
                    labelName="NOME DO CAMPO*"
                    [form]="form"
                    [inputModel]="clientForm?.label"
                    [disabled]="clientForm?.draggable || false"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-input
                    id="json_name"
                    name="json_name"
                    placeholder="ex: contacts, documents, etc"
                    labelName="NOME OU TIPO EM JSON*"
                    [form]="form"
                    [inputModel]="clientForm?.json_name"
                    [disabled]="clientForm?.draggable || false"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-input
                    id="max_length"
                    name="max_length"
                    placeholder="Quantidade maxima de letras"
                    labelName="QUANTIDADE MAXIMA DE LETRAS*"
                    [form]="form"
                    [inputModel]="clientForm?.max_length || 100"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-select
                    id="required"
                    name="required"
                    [options]="options"
                    labelName="ESTE CAMPO É OBRIGATORIO?*"
                    [form]="form"
                    [inputModel]="clientForm?.required || options[1].value"
                    [disabled]="clientForm?.draggable || false"
                ></app-select>
            </div>
            
            <div class="col-lg-12 mb-8">
                <div class="d-flex align-items-center">
                    <label class="label-title me-3 mb-0">ESTE CAMPO É DESATIVADO?</label>
                    <app-checkbox
                        id="disabled"
                        name="disabled"
                        [form]="form"
                        [inputModel]="clientForm?.disabled || false"
                    ></app-checkbox>
                </div>
            </div>

            <div class="col-lg-12">
                <app-input
                    id="intial_value"
					name="intial_value"
					placeholder="PREENCHA AQUI O VALOR INICIAL DESTE CAMPO"
					labelName="VALOR INICIAL"
					[form]="form"
					[inputModel]="clientForm?.intial_value || ''"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-select
                    id="input_type"
                    name="input_type"
                    [options]="inputTypes"
                    labelName="TIPO DE INPUT*"
                    [form]="form"
                    [inputModel]="clientForm?.input_type || inputTypes[0].value"
                ></app-select>
            </div>

            <app-input
                id="input_mask"
                name="input_mask"
                placeholder="(##) #####-####"
                labelName="MASCARA DE FORMATAÇÃO"
                [form]="form"
                [inputModel]="clientForm?.input_mask"
            ></app-input>

            <div
                class="col-lg-12"
                [class]="{ 'd-none': !visible.json }"
            >
                <app-select
                    id="json_field_is_array"
                    name="json_field_is_array"
                    [options]="options"
                    labelName="ESTE CAMPO É ESTILO OBJETO JSON?"
                    [form]="form"
                    (changeValue)="showOptionsOrList($event, 'json')"
                    [inputModel]="!!(clientForm && clientForm.json_field_is_array && clientForm.json_object && clientForm.json_object.length > 0)"
                ></app-select>
            </div>

            <div
                class="col-lg-12"
                [class]="{ 'd-none': !visible.options }"
            >
                <app-select
                    id="multipleOptions"
                    name="multipleOptions"
                    [options]="options"
                    labelName="ESTE CAMPO É DE MULTIPLA ESCOLHA?"
                    [form]="form"
                    (changeValue)="showOptionsOrList($event, 'options')"
                    [inputModel]="!!(clientForm && clientForm.input_options && clientForm.input_options.length > 0)"
                ></app-select>
            </div>

            <div *ngIf="visible.json && isMultiplesOptions || visible.options && isMultiplesOptions">
                <label class="label-title">
                    OBJETOS RELACIONADOS {{ visible.json ? 'AO JSON' : 'A MULTIPLA ESCOLHA' }}*
                </label>
                <div class="row mb-4" *ngFor="let field of dataFields; let idx = index">
                    <div class="col-6">
                        <input
                            class="form-control"
                            [value]="fieldObject[idx].label || ''"
                            type="text"
                            placeholder="Chave"
                            (keyup)="setValueAndKeyField('label', $event, idx)"
                        >
                    </div>

                    <div class="col-5">
                        <input
                            class="form-control"
                            [value]="fieldObject[idx].value || ''"
                            type="text"
                            placeholder="Valor"
                            (keyup)="setValueAndKeyField('value', $event, idx)"
                        >
                    </div>

                    <div class="col-1 d-flex align-items-center" *ngIf="idx > 0">
                        <button
                            type="button"
                            class="btn btn-danger hover-light cursor-pointer p-1"
                            (click)="clearField(idx)"
                        >
                            <app-svg name="delete"></app-svg>
                        </button>
                    </div>
                </div>

                <button
                    class="w-100 btn btn-primary hover-light cursor-pointer p-1 mt-5"
                    type="button"
                    (click)="addingField()"
                >
                    adicionar outro item
                </button>
            </div>
        </div>

        <div class="d-flex w-100 justify-content-end mt-7">
            <button
                class="btn btn-success cursor-pointer m-3"
                [disabled]="form.invalid"
                (click)="confirmation()"
            >
                Salvar
            </button>
            <button class="btn btn-danger cursor-pointer m-3" (click)="close()">
                Cancelar
            </button>
        </div>
    </form>
</div>
