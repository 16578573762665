<div *ngIf="!subItems || !subItems?.length" class="menu-item">
	<a
		class="menu-link"
		[routerLink]="link"
		routerLinkActive="active"
		[routerLinkActiveOptions]="{ exact: true }"
	>
		<span class="menu-icon">
			<span class="svg-icon svg-icon-2" [title]="title">
				<app-svg [name]="icon"></app-svg>
			</span>
		</span>
		<span class="menu-title">{{ title }}</span>
	</a>
</div>

<div
	*ngIf="subItems?.length"
	data-kt-menu-trigger="click"
	[class]="'menu-item menu-accordion ' + (activeSubItem ? 'show hover' : '')"
>
	<span class="menu-link" (click)="toggleShowSubItems()">
		<span class="menu-icon">
			<span class="svg-icon svg-icon-2" [title]="title">
				<app-svg [name]="icon"></app-svg>
			</span>
		</span>
		<span class="menu-title">{{ title }}</span>
		<span class="menu-arrow"></span>
	</span>
	<div class="menu-sub menu-sub-accordion menu-active-bg">
		<div class="menu-item" *ngFor="let subItem of subItems">
			<a
				class="menu-link"
				[routerLink]="subItem.link"
				routerLinkActive="active"
				[routerLinkActiveOptions]="{ exact: true }"
			>
				<span class="menu-bullet">
					<span class="bullet bullet-dot"></span>
				</span>
				<span class="menu-title">{{ subItem.title }}</span>
			</a>
		</div>
	</div>
</div>
