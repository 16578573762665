import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { inputTypeList } from 'src/app/mocks/client';
import { selectType } from 'src/app/models/selectType'
import { Form } from 'src/app/models/form';

@Component({
    selector: 'app-client-forms',
    templateUrl: './client-forms.component.html',
    styleUrls: ['./client-forms.component.scss']
})
export class ClientFormsComponent implements OnInit {
    @Input() callbackConfirmation?: Function
    @Input() clientForm?: Form
    form: FormGroup
    options: selectType[]
    dataFields: number[] = []
    fieldObject: selectType[] = [{ label: '', value: '' }]
    inputTypes: selectType[] = inputTypeList
    isMultiplesOptions: Boolean = false
    visible: { options: boolean, json: boolean } = { options: true, json: true }
    dataForm?: Form
    fieldIsArray: boolean = false
    display: boolean = false

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal
    ) {


        this.form = this.formBuilder.group({
            label: [this.clientForm?.label || null, Validators.required],
            json_name: [this.clientForm?.json_name || null, Validators.required],
            json_field_is_array: [this.clientForm?.json_field_is_array, Validators.required],
            input_type: [this.clientForm?.input_type || null, Validators.required],
            input_mask: [this.clientForm?.input_mask || null],
            required: [this.clientForm?.required || null, Validators.required],
            disabled: [this.clientForm?.disabled || null],
            intial_value: [this.clientForm?.intial_value || null],
            max_length: [this.clientForm?.max_length || 100, Validators.required],
            multipleOptions: false,
        })

        this.options = [
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
        ]
    }

    ngOnInit(): void {
        if (this.clientForm) {
            this.clientExistsOptionsOrObjects()
            this.verifyVisibleOptionsOrObjects()
        }
        setTimeout(() => { this.display = true }, 1);
    }

    verifyVisibleOptionsOrObjects() {
        const inputOptionsExists = !!(this.clientForm?.input_options &&
            this.clientForm?.input_options.length > 0)

        const fieldIsArray = !!(this.clientForm && this.clientForm.json_field_is_array && this.clientForm.json_object && this.clientForm.json_object.length > 0)

        if (inputOptionsExists || fieldIsArray) {
            this.visible.options = inputOptionsExists
            this.visible.json = fieldIsArray
            this.isMultiplesOptions = this.visible.options || this.visible.json
            this.fieldIsArray = fieldIsArray
        }
    }

    clientExistsOptionsOrObjects() {
        if (this.clientForm?.input_options && this.clientForm.input_options.length > 0) {
            this.fieldObject = this.clientForm?.input_options
            this.addingFieldsMultiples(this.clientForm?.input_options.length)
            return
        } else if (this.clientForm?.json_object?.length) {
            const itemsJson = Object.entries(this.clientForm?.json_object[0])
            this.fieldObject = this.formattingItemToSelectStyle(itemsJson)
            this.addingFieldsMultiples(itemsJson.length)
            return
        }
    }

    setValueAndKeyField(type: any, e: any, index: number) {
        const target = e.target.value
        if (type === 'label') {
            this.fieldObject[index].label = target
        } else if (type === 'value') {
            this.fieldObject[index].value = target
        }
    }

    addingFieldsMultiples(qntd: number) {
        for (let i = 0; i < qntd; i++) {
            const lastField = this.dataFields[this.dataFields.length - 1]
            this.dataFields.push(lastField + 1)
        }
    }

    addingField() {
        const lastField = this.dataFields[this.dataFields.length - 1]
        this.dataFields.push(lastField + 1)
        this.fieldObject.push({ label: '', value: '' })
    }

    clearField(index: number) {
        this.fieldObject.splice(index, 1)
        this.dataFields.splice(index, 1)
    }

    showOptionsOrList(value: any, type: string) {
        const target = value === "true"
        this.isMultiplesOptions = target

        if (type === 'options') {
            this.visible.options = true
            this.visible.json = false
            this.dataFields = [1]
            this.fieldObject = [{ label: '', value: '' }]
        }

        if (type === 'json') {
            this.fieldIsArray = target
            this.visible.options = false
            this.visible.json = true
            this.dataFields = [1]
            this.fieldObject = [{ label: '', value: '' }]
        }

        this.resetVisibles()
    }

    resetVisibles() {
        if (!this.isMultiplesOptions) {
            this.visible.json = true
            this.visible.options = true
            this.clearFielsAll()
        }
    }

    mountDataForm() {
        let { multipleOptions, input_mask, json_field_is_array, ...form } = this.form.value
        let json_object: any[] = []
        let input_options: any[] = []

        if (this.clientForm?.draggable) {
            form = { ...form, draggable: this.clientForm.draggable }
        }

        this.fieldObject = this.removeItemsEmpty()

        if (this.visible.options && this.fieldObject.length) {
            input_options = this.fieldObject
        }

        if (this.visible.json && this.fieldObject.length) {
            json_object = [this.formattingItemToObjectStyle(this.fieldObject, {})]
        }

        this.dataForm = {
            json_object,
            input_options,
            input_mask: input_mask || '',
            json_field_is_array: this.fieldIsArray || false,
            ...form
        }
    }

    formattingItemToObjectStyle(array: any, type?: any, index?: number) {
        const fieldInList = index ? Array(array[index]) : array
        return fieldInList.reduce((acc: any, item: any) => {
            acc[item.label] = item.value
            return acc
        }, type)
    }

    formattingItemToSelectStyle(array: any) {
        return array.map((field: any) => ({ label: field[0], value: field[1] }))
    }

    removeItemsEmpty(): selectType[] | any {
        if (!this.fieldObject.length) return []
        const fieldsNotEmpty = this.fieldObject.filter((field: any) => !!field.label !== false)
        if (fieldsNotEmpty.length !== this.dataFields.length || fieldsNotEmpty.length === 0) {
            const qntdItemsremoveds = this.dataFields.length - fieldsNotEmpty.length
            this.dataFields.splice(0, qntdItemsremoveds)
        }
        return fieldsNotEmpty
    }

    clearFielsAll() {
        this.fieldObject.splice(0, this.fieldObject.length)
        this.fieldObject = [{ label: '', value: '' }]
        this.dataFields.splice(0, this.dataFields.length)
        this.dataFields = [1]
    }

    close() {
        this.activeModal.close()
    }

    confirmation() {
        if (this.form.value.label) {
            this.mountDataForm()
            if (this.callbackConfirmation) {
                if (this.clientForm) {
                    this.callbackConfirmation(this.dataForm)
                    return
                }
                this.callbackConfirmation()
            }
        }
    }
}
