import { Component, NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './pages/login/login.component'
import { NoAuthGuard } from './guard/no-auth.guard'
import { AuthGuard } from './guard/auth.guard'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { UsersComponent } from './pages/panel/users/users.component'
import { PanelComponent } from './pages/panel/panel.component'
import { ComponentsComponent } from './pages/panel/components/components.component'
import { ProfileComponent } from './pages/panel/profile/profile.component'
import { CreateUserComponent } from './pages/panel/create-user/create-user.component'
import { ClientsComponent } from './pages/panel/clients/clients.component'
import { ProfileClientComponent } from './pages/panel/profile-client/profile-client.component'
import { FormClientComponent } from './pages/panel/form-client/form-client.component'

const rolesAdmin = ['manager', 'admin']

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [NoAuthGuard],
	},
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full',
	},
	{
		path: 'panel',
		redirectTo: '/panel/users',
		pathMatch: 'full',
	},
	{
		path: 'panel',
		component: PanelComponent,
		data: {
			title: 'Painel',
		},
		children: [
			{
				path: 'users',
				component: UsersComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Lista de usuários',
				},
			},
            {
				path: 'adicionar-novo-usuario',
				component: CreateUserComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Adicionar novo usuário',
				},
			},
			{
				path: 'perfil/:id',
				component: ProfileComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Perfil',
				},
			},
            {
				path: 'perfil/:id/:slug',
				component: ProfileComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Perfil',
				},
			},
			{
				path: 'clientes',
				component: ClientsComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Lista de Empresas',
				},
			},
			{
				path: 'cliente',
				component: FormClientComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Adicionar novo Cliente',
				},
			},
            {
				path: 'cliente/:id',
				component: ProfileClientComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Cliente',
				},
			},
			{
				path: 'componentes',
				component: ComponentsComponent,
				canActivate: [AuthGuard],
				data: {
					roles: rolesAdmin,
					title: 'Exemplos de componentes',
				},
			},
		],
	},
	{
		path: '404',
		component: NotFoundComponent,
		data: { title: 'Página não encontrada' },
	},
	{
		path: '**',
		redirectTo: '/404',
		data: { title: 'Página não encontrada' },
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
