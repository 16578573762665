import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ListSteps } from '../../../models/steps'

@Component({
	selector: 'app-modal-default',
	templateUrl: './modal-steps.component.html',
	styleUrls: ['./modal-steps.component.scss'],
})
export class ModalStepsComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() title?: string
	@Input() desc?: string
	@Input() listSteps?: ListSteps[] = []

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	confirmation() {
		if (this.callbackConfirmation) this.callbackConfirmation()
		this.close()
	}
}
