const api = 'https://dev-api.haitech.network'

export const environment = {
    api: `${api}/api/v1/main`,
    memedUrl: 'https://memed.com.br',
    memedApi: 'https://api.memed.com.br/v1',
    ws: 'https://dev.haitech.network',
    redirects: {
        roles: {
            admin: '/panel',
            public: '/',
        },
    },
    env: 'dev',
    production: false,
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
