import { Component, OnInit } from '@angular/core'
import { TimelineItem } from '../../../models/timeline'
import { User } from '../../../models/user'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ListSteps } from '../../../models/steps'
import { Users } from '../../../models/users'
import { ListHeader } from '../../../models/list-header'
import { listHeaderUsers } from '../../../mocks/users'
import { UserService } from '../../../services/user/user.service'
import { ModalDefaultComponent } from '../../../components/modals/modal-default/modal-default.component'
import { ToastrService } from 'ngx-toastr'
import { ClientInfo } from 'src/app/models/client'

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
	listTimeline: TimelineItem[] = []
	userData?: User
	listSteps?: ListSteps[] = []
	listProgress?: any
	listUsers?: Users[]
	listHeaderUsers: ListHeader[]
    listFilters: any

	constructor(
		private modalDefault: NgbModal,
		private modalService: NgbModal,
		private userService: UserService,
		private toast: ToastrService
	) {
		this.listHeaderUsers = listHeaderUsers
		this.userData = {
			id: '12',
			name: 'Tiago',
			lastName: 'Marques',
			birthdate: '12/12/2000',
			email: 'tiago@tiago.com',
			roles: ['admin'],
		}

		this.listSteps = [
			{
				stepNumber: 1,
				name: 'Teste 1',
				description: 'Testando 1',
				isActive: true,
			},
			{
				stepNumber: 2,
				name: 'Teste 2',
				description: 'Testando 2',
				isActive: false,
			},
			{
				stepNumber: 3,
				name: 'Teste 3',
				description: 'Testando 3',
				isActive: false,
			},
		]
	}

	ngOnInit(): void {
		const self = this
		this.userService.getAllUsers({
			fnSuccess(response) {
                self.listFilters = response.items
				self.listUsers = response.items
			},
			fnError(responseError) {
				console.warn(responseError)
			},
		})
	}

	searchUsers(userSearch: any) {
        userSearch = userSearch.value.trim().toLowerCase()
        this.listFilters = this.listUsers?.filter(({ name }: any) => {
            name = name.trim().toLowerCase()
            return name.includes(userSearch)
        })
	}

	sliceClientsView(clients: ClientInfo[] | any) {
        if(clients.length > 3) {
            const clientsFirsts = clients.slice(0, 3)
            const moreClients = clients.slice(3, clients.length)
            clientsFirsts.push({ dots: '...', moreClients })
            return clientsFirsts
        }
        return clients
	}

	openScrollableContent(longContent: any) {
		this.modalService.open(longContent, { scrollable: true })
	}

	open(id: string) {
		const modalRef = this.modalDefault.open(ModalDefaultComponent)
		modalRef.componentInstance.title = 'Excluir usuário?'
		modalRef.componentInstance.desc = 'Essa a ação não poderá ser desfeita.'
		modalRef.componentInstance.callbackConfirmation = () => {
			modalRef.close()
			this.userService.deleteUser(id, () => {
				this.toast.success('Excluido com sucesso')
			})
		}
	}
}
