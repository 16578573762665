<div class="d-flex flex-column flex-root">
    <div
        class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style="background-image: url(assets/images/media/illustrations/progress-hd.png)">
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <a class="mb-12">
                <img alt="Logo" src="assets/images/logo-blue.svg" class="h-45px"/>
            </a>
            <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                <form class="form w-100" action='#' autocomplete='off'
                      (ngSubmit)='onSubmit()'
                      [formGroup]='form'>
                    <div class="text-center mb-10">
                        <h1 class="text-dark mb-3">Entrar no painel</h1>
                    </div>
                    <div class="fv-row mb-10">
                        <app-input
                            id='email'
                            name='email'
                            typeInput='email'
                            placeholder='Digite seu email cadastrado'
                            labelName='email'
                            [form]='form'
                        ></app-input>
                    </div>
                    <div class="fv-row mb-10">
                        <app-input
                            id='password'
                            name='password'
                            typeInput='password'
                            placeholder='Digite sua senha'
                            labelName='Senha'
                            [form]='form'
                        ></app-input>
                        <div class="d-flex flex-stack mb-2">
                            <a href="#" class="link-primary fs-6 fw-bolder">Esqueceu sua senha?</a>
                        </div>
                    </div>
                    <div class="text-center">
                        <button type='submit' class='btn btn-primary w-100' [disabled]='form.invalid'>Entrar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
