<app-header [profile]="userData"></app-header>
<div class="col-12 pb-5">
	<app-title title="Profile" tDetach="Overview" type="primary"></app-title>
	<!--	<app-card-profile-header [profile]="userData" [profileTitle]="itemSelected">-->
	<!--		<div profileHeader>-->
	<!--			<ul-->
	<!--				class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder"-->
	<!--			>-->
	<!--				<li-->
	<!--					class="nav-item mt-2"-->
	<!--					*ngFor="let menu of listMenu; index as i"-->
	<!--				>-->
	<!--					<button-->
	<!--						type="button"-->
	<!--						class="nav-link text-active-primary ms-0 me-10 py-5 bg-white"-->
	<!--						[class.active]="itemSelected === menu.title"-->
	<!--						(click)="setIdActiveItem(menu)"-->
	<!--					>-->
	<!--						{{ menu.title }}-->
	<!--					</button>-->
	<!--				</li>-->
	<!--			</ul>-->
	<!--		</div>-->
	<!--		<div profileContent>-->
	<!--			{{ itemSelected }}-->
	<!--		</div>-->
	<!--	</app-card-profile-header>-->
</div>

<div class="row pb-5">
	<app-title title="Progress" tDetach="Card" type="warning"></app-title>
	<div class="col-6" *ngFor="let item of listProgress">
		<app-progress [progress]="item"></app-progress>
	</div>
</div>

<div class="row">
	<div class="col-lg-5 col-xl-4">
		<app-title tDetach="Timeline"></app-title>
		<app-timeline
			title="Titulo de teste"
			subTitle="R$10,00 reais"
			[list]="listTimeline"
		></app-timeline>
	</div>

	<div class="col-lg-5 col-xl-4">
		<app-title tDetach="List ToDo"></app-title>
		<app-todo title="Titulo de Todo Teste" [list]="listTodo"></app-todo>
	</div>
</div>

<div class="row">
	<div class="col-6">
		<app-title
			title="Feed"
			tDetach="Comments"
			config="pt-5"
			type="danger"
		></app-title>
		<app-feed></app-feed>
	</div>
	<div class="col-6">
		<app-title
			title="List"
			tDetach="Trends"
			config="pt-5"
			type="success"
		></app-title>
		<app-trends
			title="Titulo"
			subTitle="Subtitulo da seção"
			[trends]="listTrends"
		></app-trends>
	</div>
</div>
