import {Injectable} from '@angular/core'
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http'
import {BehaviorSubject, Observable, throwError} from 'rxjs'
import {AuthService} from './auth.service'
import {environment} from '../../../environments/environment'
import {catchError, filter, switchMap, take} from 'rxjs/operators'

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    private isRefreshing = false
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    constructor(public authService: AuthService) {
    }

    private addToken(req: HttpRequest<any>) {
        let token = this.authService.getToken()
        let cloned = req.clone()

        if (token) {
            cloned = req.clone({
                headers: req.headers.set('env', environment.env).set('Authorization', 'Bearer ' + token)
            })
        }

        return cloned
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true
            this.refreshTokenSubject.next(null)

            return this.authService.refreshToken().pipe(
                switchMap((data: any) => {
                    const token = data.data.access_token
                    this.isRefreshing = false
                    this.refreshTokenSubject.next(token)
                    return next.handle(this.addToken(req))
                }))

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(() => {
                    return next.handle(this.addToken(req))
                }))
        }
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const cloned = this.addToken(req)

        return next.handle(cloned).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        return this.handle401Error(req, next)
                    } else if (
                        error.status === 403 &&
                        error.url?.includes('refresh')
                    ) {
                        this.authService.logout()
                        // @ts-ignore
                        window.location = '/login'
                        return throwError(error)
                    }
                    return throwError(error)
                } else {
                    return throwError(error)
                }
        }))
    }
}

