import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ClientInfo } from 'src/app/models/client';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { Contract } from 'src/app/models/contract';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
    @Input() client?: ClientInfo
    id: string = ''
    showFormContract: boolean = false
    contracts?: Contract[]

    constructor(
        private route: ActivatedRoute,
        private contractService: ContractClientService,
        public helpers: HelpersService
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.params.id
        this.contracts = this.client?.contracts
    }

    toggleFormContract() {
        this.showFormContract = !this.showFormContract
    }

    addContract(body: any) {
        const { contract_type_id, ...payload } = body
        const self = this
        this.contractService.getContractTypes(contract_type_id, {
            fnSuccess({ name }) {
                const contractCurrent = {
                    contracts_type: { name },
                    created_at: new Date(),
                    ...payload
                }
                self.contracts?.push(contractCurrent)
            }, fnError(e) { console.warn(e) }
        })
        this.toggleFormContract()
    }
}
