import { Form } from 'src/app/models/form';

export const fieldsDefault: Form[] = [
    {
        input_mask: "###.###.###-##",
        input_options: [],
        input_type: "number",
        json_field_is_array: true,
        json_name: "documents",
        json_object: [{ document_type: "cpf", value: "" }],
        label: "CPF",
        required: true,
        draggable: true,
        max_length: 11,
    },
    {
        input_mask: "",
        input_options: [],
        input_type: "text",
        json_field_is_array: false,
        json_name: "name",
        json_object: [],
        label: "Nome",
        required: true,
        draggable: true,
        max_length: 100
    },
    {
        label: "Sobrenome",
        json_name: "last_name",
        json_field_is_array: false,
        json_object: [],
        input_type: "text",
        input_options: [],
        input_mask: "",
        max_length: 100,
        draggable: true,
        required: true,
    },
    {
        input_mask: "##/##/####",
        input_options: [],
        input_type: "number",
        json_field_is_array: false,
        json_name: "birthdate",
        json_object: [],
        label: "Data de nascimento",
        required: true,
        draggable: true,
        max_length: 8
    },
    {
        input_mask: "",
        input_options: [
            {
                label: "Feminino",
                value: "F"
            },
            {
                label: "Masculino",
                value: "M"
            }
        ],
        input_type: "radio",
        json_field_is_array: false,
        json_name: "gender",
        json_object: [],
        required: true,
        label: "Sexo Biológico",
        draggable: true,
        max_length: 100
    },
    {
        label: "E-mail",
        json_name: "contacts",
        json_field_is_array: true,
        json_object: [{ type: "email", value: "" }],
        input_type: "email",
        input_options: [],
        input_mask: "",
        max_length: 300,
        draggable: true,
        required: false
    },
    {
        label: "Telefone",
        json_name: "contacts",
        json_field_is_array: true,
        json_object: [{ type: "phone", value: "" }],
        input_type: "tel",
        input_options: [],
        input_mask: "(##) #.####-####",
        max_length: 11,
        draggable: true,
        required: true
    }
]
