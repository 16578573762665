import { Component, OnInit } from '@angular/core';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { Protocol } from 'src/app/models/protocol';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { device } from 'src/app/models/device';
import { ToastrService } from 'ngx-toastr';
import { protocolExam } from 'src/app/models/device';
@Component({
  selector: 'app-protocol',
  templateUrl: './protocol.component.html',
  styleUrls: ['./protocol.component.scss']
})
export class ProtocolComponent implements OnInit {
    protocol?: Protocol
    form: FormGroup
    protocolsClient: device[] = []
    clientId: string = ''
    show: boolean = false
    clientIdSent: string = ''
    examsList: device[] = []
    protocolId: string = ''
    protocolsSents: any[] = []
    submiting: boolean = false

    constructor(
        private protocolService: ContractClientService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private toast: ToastrService,
    ) {
        this.form = this.formBuilder.group({
            frontendName: [null, Validators.required],
            description: [null, Validators.required],
            frontendDescription: [null, Validators.required],
            nameProtocol: [null, Validators.required],
        })
    }

    ngOnInit(): void {
        this.clientId = this.route.snapshot.params.id
        const self = this
        this.protocolService.getClientsProtocols(this.clientId, {
            fnSuccess(data) {
                self.protocol = data
                self.protocolId = data.id
                self.getProtocolsExamsClient()
                self.mountForm()
            }, fnError(e) { console.warn('ERROR', e) }
        })
    }

    getProtocolsExamsClient() {
        const self = this
        if(this.protocolId) {
            this.protocolService.getProtocolsExams(this.protocolId, {
                fnSuccess(data) {
                    self.examsList = self.sortingByCaptured(data , 'exams')
                    self.protocolsClient = self.sortingByCaptured(data)
                    self.addDevicesClientInData(self.examsList)
                    self.addDevicesClientInData(self.protocolsClient)
                }, fnError(e) { console.warn('ERROR', e) }
            })
        }
    }

    sortingByCaptured(data: any, type?: string){
        const devicesList: any = []
        const capturedsList: any = []
        const examsSent: any = []

        data.map((item: any) => {
            const { device_models } = item
            if(device_models.length) {
                device_models.map(({ captured_by, id, preferred }: any) => {
                    const capturedExist = capturedsList.find(
                        (captured: any) => captured.id === id && captured.captured_by === captured_by
                    )

                    if(!capturedExist) {
                        capturedsList.push({ captured_by, id })
                        devicesList.push({
                            capturedBy: captured_by,
                            device: id,
                            exams: [ item.exam_id ],
                            protocolsExams: [ {
                                protocol_exam: item.id,
                                exam_id: item.exam_id,
                                preferred
                            } ]
                        })
                    } else {
                        devicesList.map((device: any) => {
                            if(device.capturedBy === captured_by && device.device === id) {
                                device.exams.push(item.exam_id)
                                device.protocolsExams.push(
                                    { protocol_exam: item.id, exam_id: item.exam_id, preferred }
                                )
                            }
                        })
                    }
                })
            } else if(devicesList.length) {
                examsSent.push({ protocol_exam: item.id, exam_id: item.exam_id })
            }
        })

        const examsWithoutDevices = devicesList.length && examsSent.length

        if(examsWithoutDevices && type === 'exams') {
            devicesList[0].examsSent = []
            devicesList[0].examsSent = examsSent
        }
        this.showForm()
        return devicesList
    }

    addDevicesClientInData(data: device[]) {
        this.protocolsSents = []

        data.forEach((protocol: device) => {
            const { protocolsExams, exams } = protocol
            const protocolItem = { protocolsExams, exams }
            this.getDeviceModelClient(protocolItem, (item: any) => {
                if(!item.multiple) {
                    const { multiple, ...items } = item
                    if(!protocol.devicesSent?.length) {
                        protocol.devicesSent = [ items ]
                        return
                    }
                    protocol.devicesSent.push(items)
                } else {
                    this.getExamsShareds(data, item)
                }
            })
        })
    }

    getExamsShareds(data: any, item: any) {
        data.forEach((element: any) => {
            element.protocolsExams?.forEach((protocol: any) => {
                if(
                    protocol.protocol_exam === item.protocol_exam &&
                    element.device === item.device_model_id
                ) {
                    const { device_model_id, multiple, ...itemShared } = item
                    if(!element.devicesSent?.length) {
                        element.devicesSent = [ itemShared ]
                        return
                    }
                    element.devicesSent.push(itemShared)
                }
            })
        });
    }

    getDeviceModelClient(protocolItem: any, callback?: Function) {
        const { exams, protocolsExams } = protocolItem
        if(protocolsExams.length) {
            protocolsExams.forEach((protocol: any, index: number) => {
                const { protocol_exam } = protocol
                if(!this.protocolsSents.includes(protocol_exam)) {
                    this.protocolsSents.push(protocol_exam)
                    this.protocolService.getDeviceModel(protocol_exam, {
                        fnSuccess(data) {
                            const exam_id = exams[index]
                            if(data.length < 2) {
                                const { id } = data[0]
                                const item = { device_id: id, protocol_exam, exam_id, multiple: false }
                                if(callback) callback(item)
                            } else {
                                const items: any[] = []
                                data.forEach((item: any) => {
                                    items.push({
                                        device_id: item.id,
                                        protocol_exam,
                                        multiple: true,
                                        device_model_id: item.device_model_id,
                                        exam_id,
                                    })
                                })

                                items.forEach((item: any) => { if(callback) callback(item) })
                            }
                        }, fnError(e) { console.warn('ERROR', e) }
                    })
                }
            })
        }
    }

    getClientIdSent() {
        this.submiting = true
        this.clientIdSent = this.clientId

        setTimeout(() => {
            this.clientIdSent = ''
        }, 100)
    }

    mountForm() {
        this.form = this.formBuilder.group({
            frontendName: [this.protocol?.frontendName, Validators.required],
            description: [this.protocol?.description, Validators.required],
            frontendDescription: [this.protocol?.frontendDescription, Validators.required],
            nameProtocol: [this.protocol?.name, Validators.required],
        })
    }

    showForm() {
        this.show = false
        setTimeout(() => {
            this.show = true
        }, 1)
    }

    refreshProtocols(submit: any) {
        if(submit.submited) {
            this.show = false
            this.getProtocolsExamsClient()
            this.toast.success('Exames e Devices atualizados')
            this.submiting = false
        }
    }
}
