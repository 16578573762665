<div
	class="container d-flex align-items-stretch justify-content-between py-4 px-0"
>
	<div></div>
	<div
		class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
	>
		<div></div>
		<div class="d-flex align-items-stretch flex-shrink-0">
			<div class="d-flex align-items-stretch ms-1 ms-lg-3">
				<div
					id="kt_header_search"
					class="header-search d-flex align-items-stretch"
				>
					<div class="d-flex align-items-center">
						<div
							class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
						>
							<span class="svg-icon svg-icon-1">
								<app-svg name="search"></app-svg>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex align-items-center ms-1 ms-lg-3">
				<div
					class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative"
					id="kt_drawer_chat_toggle"
				>
					<span class="svg-icon svg-icon-1" (click)="openChat()">
						<app-svg name="chat"></app-svg>
					</span>
					<span
						*ngIf="bulletNewMessage"
						class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"
					></span>
				</div>
			</div>
			<div
				class="d-flex align-items-center ms-1 ms-lg-3"
				id="kt_header_user_menu_toggle"
			>
				<div
					class="cursor-pointer symbol symbol-30px symbol-md-40px w-30px"
				>
					<app-image
						path="https://picsum.photos/30/30"
						title="User"
						[load]="true"
					></app-image>
				</div>
			</div>
		</div>
	</div>
</div>

<app-chat
	[user]="profile"
	[hasOpen]="hasOpenChat"
	(emmitEventClose)="openChat()"
></app-chat>
