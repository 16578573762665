<div class="wrapper-profile">
	<div class="image">
		<app-image-decrypt
			*ngIf="blob && profile && reload"
			[load]="true"
			[path]="image || blob"
			[title]="profile.name + ' ' + profile.lastName"
		></app-image-decrypt>
        <app-image
            *ngIf="blob && client && reload"
            [load]="true"
            [path]="image || blob"
            [title]="'logo' + client.name"
        ></app-image>
		<app-image
			*ngIf="!blob || !reload"
			path="../../assets/images/error-image.png"
			[title]="(profile?.name + ' ' + profile?.lastName) || client?.name"
		></app-image>
		<div class="file">
			<form enctype="multipart/form-data">
				<label for="file"></label>
				<input
					id="file"
					type="file"
					name="file"
					(change)="fileChangeEvent($event)"
				/>
			</form>
		</div>
	</div>
	<div class="modal-file modal-body" *ngIf="openModalFile">
		<div class="close-file" (click)="onCloseModalFile()"></div>
		<div class="crop-image">
			<image-cropper
				[imageChangedEvent]="imageChangedEvent"
				[maintainAspectRatio]="true"
                [containWithinAspectRatio]="true"
				[aspectRatio]="4 / 4"
				format="png"
				(imageCropped)="imageCropped($event)"
				[transform]="transform"
				(loadImageFailed)="loadImageFailed()"
			></image-cropper>
			<div class="wrapper-button">
				<div class="configs">
					<button
						type="button"
						class="btn btn-primary"
						[class.disabled]="scale === 1"
						(click)="zoomOut()"
					>
						Zoom -
					</button>
					<button
						type="button"
						class="btn btn-primary"
						(click)="zoomIn()"
					>
						Zoom +
					</button>
				</div>
				<button
					(click)="sendFileImageProfile(profile?.id || client?.id)"
					type="button"
					class="btn btn-primary"
				>
					Salvar
				</button>
			</div>
		</div>
	</div>
</div>
