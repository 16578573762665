<ul class="nav nav-tabs">
	<li
		*ngFor="let tab of tabs"
		(click)="selectTab(tab)"
		[class.active]="tab.active"
	>
		<button
			type="button"
			class="nav-link text-active-primary ms-0 me-10 py-5"
		>
			{{ tab.title }}
		</button>
		<span *ngIf="tab.alerts">{{
			tab.alerts <= 9 ? tab.alerts : '+9'
		}}</span>
	</li>
</ul>
<ng-content></ng-content>
