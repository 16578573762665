<app-loading-progress *ngIf="loading"></app-loading-progress>
<app-card-profile-header
	[profile]="user"
	[profileTitle]="itemSelected"
	[editProfile]="true"
>
	<div profileHeader>
		<ul
			class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder"
		>
			<li class="nav-item mt-2" *ngFor="let menu of listMenu; index as i">
				<button
					type="button"
					class="nav-link text-active-primary ms-0 me-10 py-5 bg-white"
					[class.active]="itemSelected === menu.title"
					(click)="setIdActiveItem(menu)"
				>
					{{ menu.title }}
				</button>
			</li>
		</ul>
	</div>
	<div profileContent>
		<div class="row" *ngIf="itemSelected === 'profile'">
			<div class="card card-body py-5 px-5">
				<form
					action="#"
					autocomplete="off"
					(ngSubmit)="onUpdate()"
					[formGroup]="form"
				>
					<div class="row">
						<div class="col-lg-6">
							<app-input
								id="name"
								name="name"
								typeInput="text"
								placeholder="Digite seu nome"
								labelName="Nome*"
								[form]="form"
								[inputModel]="user?.name"
							></app-input>
						</div>
						<div class="col-lg-6">
							<app-input
								id="s-nome"
								name="lastName"
								typeInput="text"
								placeholder="Digite seu sobrenome"
								labelName="Sobrenome*"
								[form]="form"
								[inputModel]="user?.lastName"
							></app-input>
						</div>
						<div class="col-lg-12">
							<app-input
								id="cpf"
								name="cpf"
								typeInput="text"
								placeholder="Digite seu cpf*"
								labelName="CPF*"
								[form]="form"
								[inputModel]="user?.documentCpf"
								inputMask="000.000.000-00"
							></app-input>
						</div>
						<div class="col-lg-12">
							<app-input
								id="email"
								name="email"
								typeInput="email"
								placeholder="Digite seu E-mail"
								labelName="E-mail*"
								[form]="form"
								[inputModel]="user?.email"
							></app-input>
						</div>
						<div class="col-lg-12">
							<app-input
								id="password"
								name="password"
								typeInput="password"
								placeholder="Senha"
								labelName="Senha*"
								[form]="form"
								[inputModel]="user?.password"
							></app-input>
						</div>
						<div class="col-lg-6">
							<app-input
								id="date"
								name="birthdate"
								typeInput="date"
								placeholder="EX.: 20/07/1985"
								labelName="Data de nascimento*"
								[form]="form"
								[inputModel]="user?.birthdate"
							></app-input>
						</div>
						<div class="col-lg-6">
							<app-select
								id="gender"
								name="gender"
								[options]="genreOptions"
								labelName="Gênero*"
								placeholder="M ou F"
								[form]="form"
								[inputModel]="user?.gender"
							></app-select>
						</div>
					</div>
					<div class="wrapper-button py-5">
						<button
							type="submit"
							class="btn btn-primary"
							[disabled]="form.invalid || isSubmitting"
						>
							Atualizar dados
						</button>
					</div>
				</form>
			</div>
		</div>
		<div class="row" *ngIf="itemSelected === 'empresas'">
			<div class="col-6">
				<app-trends
					[title]="user?.clients ? 'Empresas relacionadas a este usuário' : 'Sem empresas relacionadas'"
					[trends]="user?.clients"
				></app-trends>
			</div>
		</div>
	</div>
</app-card-profile-header>
