<div class="modal-body py-lg-10 px-lg-10">
	<div
		class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid between"
		id="kt_modal_create_app_stepper"
		data-kt-stepper="true"
	>
		<div
			class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px"
		>
			<div class="stepper-nav ps-lg-10">
				<div
					class="stepper-item current"
					[class]="item.isActive ? 'current' : 'pending'"
					data-kt-stepper-element="nav"
					*ngFor="let item of listSteps"
				>
					<div class="stepper-line w-40px"></div>
					<div class="stepper-icon w-40px h-40px">
						<i class="stepper-check fas fa-check"></i>
						<span class="stepper-number">{{
							item.stepNumber
						}}</span>
					</div>
					<div class="stepper-label">
						<h3 class="stepper-title">{{ item.name }}</h3>
						<div class="stepper-desc">{{ item.description }}</div>
					</div>
				</div>
				<!-- <div class="stepper-item pending" data-kt-stepper-element="nav">-->
				<!-- 	<div class="stepper-line w-40px"></div>-->
				<!-- 	<div class="stepper-icon w-40px h-40px">-->
				<!-- 		<i class="stepper-check fas fa-check"></i>-->
				<!-- 		<span class="stepper-number">2</span>-->
				<!-- 	</div>-->
				<!-- 	<div class="stepper-label">-->
				<!-- 		<h3 class="stepper-title">Frameworks</h3>-->
				<!-- 		<div class="stepper-desc">-->
				<!-- 			Define your app framework-->
				<!-- 		</div>-->
				<!-- 	</div>-->
				<!-- </div>-->
			</div>
		</div>

		<div class="flex-row-fluid py-lg-5 px-lg-15">
			<form
				class="form fv-plugins-bootstrap5 fv-plugins-framework"
				novalidate="novalidate"
				id="kt_modal_create_app_form"
			>
				<!-- Step 1-->
				<div
					[class]="item.isActive ? 'current' : 'completed'"
					*ngFor="let item of listSteps; index as i"
				>
					<div class="w-100">
						<ng-content select="[{{ 'step' + i }}]"></ng-content>
						<!-- <h2>Step 1</h2>-->
					</div>
				</div>
				<!-- Step 2-->
				<!-- <div data-kt-stepper-element="content" class="pending">-->
				<!-- 	<div class="w-100">-->
				<!-- 		<h2>Step 2</h2>-->
				<!-- 	</div>-->
				<!-- </div>-->

				<div class="d-flex flex-stack pt-10">
					<div class="me-2">
						<button
							type="button"
							class="btn btn-lg btn-light-primary me-3"
						>
							<span class="svg-icon svg-icon-3 me-1">
								<app-svg name="arrow-left"></app-svg>
							</span>
							Back
						</button>
					</div>
					<div>
						<button
							type="button"
							class="btn btn-lg btn-primary"
							data-kt-stepper-action="submit"
						>
							<span class="indicator-label"
								>Submit
								<span class="svg-icon svg-icon-3 ms-2 me-0">
									<app-svg name="arrow-right"></app-svg>
								</span>
							</span>
							<span class="indicator-progress"
								>Please wait...
								<span
									class="spinner-border spinner-border-sm align-middle ms-2"
								></span
							></span>
						</button>
						<button
							type="button"
							class="btn btn-lg btn-primary"
							data-kt-stepper-action="next"
						>
							Continue
							<span class="svg-icon svg-icon-3 ms-1 me-0">
								<app-svg name="arrow-right"></app-svg>
							</span>
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
