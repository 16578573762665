<div class="accordion" [class.active]="isOpen">
	<div class="accordion-item border border-bottom-2 border-dark p-4 rounded">
		<div class="accordion-text">
			<div class="accordion-title" (click)="showContentFaq()">
				<h2 class="p-0 m-0">{{ title }}</h2>
			</div>
			<div *ngIf="isOpen" class="accordion-collapse mt-5">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
