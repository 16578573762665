import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { Options } from 'select2'
import { Select2OptionData } from 'ng-select2'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HelpersService } from 'src/app/services/helpers/helpers.service';

@Component({
  selector: 'app-specialties',
  templateUrl: './specialties.component.html',
  styleUrls: ['./specialties.component.scss']
})
export class SpecialtiesComponent implements OnInit {
    @Input() specialsClient: Array<Select2OptionData> = []
    @Input() specialtiesItems: Array<Select2OptionData> = []
    @Output() reloadSpecialties = new EventEmitter()
    showSpecialties: boolean = false
    options: Options
    form: FormGroup
    id: string = ''
    specialtiesRemoved: string[] = []

    constructor(
        private toast: ToastrService,
        private clientService: ClientService,
        private formBuilder: FormBuilder,
        private router: ActivatedRoute,
        public helpers: HelpersService
    ) {
        this.options = {
            width: '300',
            multiple: true,
            tags: true,
        }

        this.form = this.formBuilder.group({
            specialties: ['', Validators.required]
        })
    }

    ngOnInit(): void {
        this.id = this.router.snapshot.params.id
    }

    toggleFormSpecialties() {
        this.showSpecialties = !this.showSpecialties
        this.clearSpecialties()
    }

    clearSpecialties(){
        const { specialties } = this.form.value
        if(specialties.length > 0) {
            specialties.splice(0, specialties.length)
        }
    }

    clearSpecialtiesRemoveds() {
        this.specialtiesRemoved = []
    }

    removeSpecialtie(specialtieId: string) {
        if (!this.specialtiesRemoved.includes(specialtieId)) {
            this.specialtiesRemoved.push(specialtieId)
            return
        }
        const positionSpecialtie = this.specialtiesRemoved.indexOf(specialtieId)
        this.specialtiesRemoved.splice(positionSpecialtie, 1)
    }

    mergeSpecialties() {
        const { specialties } = this.form.value
        this.specialsClient.forEach(({ id }) => {
            if(!specialties.includes(id) && !this.specialtiesRemoved.includes(id)) {
                specialties.push(id)
            }
        })
        return { 'specialties': specialties}
    }

    onSubmit() {
        const self = this
        const data = this.mergeSpecialties()
        if (this.id && data.specialties.length) {
            this.clientService.sendSpecialtiesClient(this.id, data, {
                fnSuccess(data) {
                    self.toast.success('Especialidades adicionadas com sucesso')
                    self.toggleFormSpecialties()
                    self.reloadSpecialties.emit()
                    self.clearSpecialtiesRemoveds()
                },
                fnError(e) { console.warn('ERROR', e) }
            })
        } else {
            self.toast.error('Aconteceu algum erro na sua lista de itens selecionados')
        }
    }
}
