<figure>
	<img
		*ngIf="path"
		[defaultImage]="load ? '../../assets/images/placeholder.gif' : ''"
		errorImage="../../assets/images/error-image.png"
		[lazyLoad]="blob"
		[alt]="title"
		[title]="title"
	/>
</figure>
