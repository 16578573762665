import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { User } from '../../models/user'

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
	@Input() hasOpen: boolean = false
	@Input() user?: User
	@Output() emmitEventClose: EventEmitter<any> = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	closeChat() {
		this.hasOpen = false
		this.emmitEventClose.emit('close')
	}
}
