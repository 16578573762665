<div class="card bg-light-{{ progress?.type }} card-xl-stretch mb-xl-8">
	<div class="card-body my-3">
		<a
			href="#"
			class="card-title fw-bolder text-{{
				progress?.type
			}} fs-5 mb-3 d-block"
			>{{ progress?.title }}</a
		>
		<div class="py-1">
			<span class="text-dark fs-1 fw-bolder me-2"
				>{{ progress?.value }}%</span
			>
			<span class="fw-bold text-muted fs-7">{{
				progress?.subTitle
			}}</span>
		</div>
		<div class="progress h-7px bg-{{ progress?.type }} bg-opacity-50 mt-7">
			<div
				class="progress-bar bg-{{ progress?.type }}"
				role="progressbar"
				[style]="'width: ' + setValueProgress(progress?.value) + '%'"
				aria-valuenow="50"
				aria-valuemin="0"
				aria-valuemax="100"
			></div>
		</div>
	</div>
</div>
