import { Component, Input, OnInit } from '@angular/core'
import { TodoItem } from '../../models/todo'

@Component({
	selector: 'app-todo',
	templateUrl: './todo.component.html',
	styleUrls: ['./todo.component.scss'],
})
export class TodoComponent implements OnInit {
	@Input() title: string = ''
	@Input() list: TodoItem[] = []

	constructor() {}

	ngOnInit(): void {}
}
