<div>
    <div class="row justify-content-between">
        <div class="col-lg-6">
            <h3 class="text-center fw-bold mb-5"> Campos disponiveis </h3>
            <bs-sortable
                *ngIf="show"
                [(ngModel)]="fieldsAvailable"
                fieldName="label"
                itemActiveClass="sortableItemActive"
                itemClass="sortable-item border border-bottom-2 border-dark mb-3 rounded"
                placeholderItem="Soltar item"
                placeholderClass="text-center"
                wrapperClass="sortable-wrapper border rounded"
                [itemTemplate]="itemTemplate"
                (onChange)="onChangeAvailable($event)"
            ></bs-sortable>

            <button
                type="button"
                class="mt-10 w-100 btn btn-dark hover-light cursor-pointer mb-2 me-2"
                (click)="openModalForms()"
            >
                Adicionar Campo
            </button>
        </div>

        <div class="col-lg-6">
            <h3 class="text-center fw-bold mb-5"> Campos do formulario </h3>
            <bs-sortable
                *ngIf="show"
                [(ngModel)]="fieldsInForm"
                fieldName="label"
                itemActiveClass="sortableItemActive"
                itemClass="sortable-item border border-bottom-2 border-dark mb-3 rounded"
                placeholderItem="Soltar item"
                placeholderClass="text-center"
                wrapperClass="sortable-wrapper border rounded"
                [itemTemplate]="itemTemplate"
                (onChange)="onChangeform($event)"
            ></bs-sortable>
        </div>
    </div>

    <ng-template #itemTemplate let-item="item.initData" let-index="index">
        <div
            class="p-8 h-100 w-100 d-flex justify-content-between align-content-center"
            [class.default]="item.draggable"
        >
            {{ item.label }}
            <span
                class="svg-icon svg-icon-3 cursor-pointer"
                (click)="openModalForms(item, index)"
            >
                <app-svg name="edit"></app-svg>
            </span>
        </div>
    </ng-template>
</div>
