import {Component} from '@angular/core';
import {AppState} from "./state/app-state";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private loading$?: Observable<boolean>
    loading?: boolean

    constructor(
        private store: Store<AppState>,
    ) {
        this.loading$ = this.store.select('loading')

        this.loading$.subscribe((state) => {
            this.loading = state
        })
    }
}
