import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { finalize, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as moment from 'moment'
import { environment } from '../../../environments/environment'
import { AuthResult } from './auth-result'
import { AddUser, ClearUser } from '../../state/user/user.action'
import { AppState } from '../../state/app-state'
import { Login } from '../../models/login'
import { Router } from '@angular/router'
import { HelpersService } from '../helpers/helpers.service'
import { ToastrService } from 'ngx-toastr'
import { StatsService } from '../stats/stats.service'
import { Client } from '../../models/client'
import { User } from '../../models/user'
import { VersionService } from '../version/version.service'

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	isLogin = false
	roleAs: string[] = ['public']

	constructor(
		private http: HttpClient,
		private store: Store<AppState>,
		private router: Router,
		private helpers: HelpersService,
		private toast: ToastrService,
		private statsService: StatsService
	) {}

	login(body: Login) {
		body.payload = {
			...body.payload,
			email: body.payload.email.toLowerCase(),
		}

		return this.http
			.post<any>(`${environment.api}/auth/login`, body.payload)
			.pipe(
				finalize(() => {
					if (body.fnFinalized) body.fnFinalized()
				})
			)
			.subscribe((data) => {
				const resultData: AuthResult = data.data
				this.setSession(resultData)
				body.fnSuccess()
			}, body.fnError)
	}

	refreshToken() {
		const token = this.getRefreshToken()
		return this.http
			.post<any>(`${environment.api}/auth/refresh`, {
				refresh_token: token,
			})
			.pipe(
				tap((data) => {
					const resultData: AuthResult = data.data
					this.setSession(resultData)
				})
			)
	}

	logout() {
		// this.statsService.stats('logout_admin', 'start')
		this.isLogin = false
		this.roleAs = ['public']
		localStorage.removeItem('roles')
		localStorage.removeItem('token')
		localStorage.removeItem('meMedComplete')
		localStorage.removeItem('refresh_token')
		localStorage.removeItem('expires_at')
		this.setUserState()
		// this.statsService.stats('logout_admin', 'end')
		this.store.dispatch(ClearUser())
		return of({ success: this.isLogin, role: this.roleAs })
	}

	private setSession({
		access_token,
		refresh_token,
		expires_at,
	}: AuthResult) {
		const { user } = this.helpers.decodePayloadJWT(access_token)
		const expiresAt = moment().add(expires_at, 'second')

		this.roleAs = user.roles

		if (!this.roleAs.includes('admin')) {
			this.toast.error(
				'Este usuário não tem permissão para acessar o Admin!'
			)
		} else {
			this.isLogin = true
			localStorage.setItem('roles', JSON.stringify(this.roleAs))
			localStorage.setItem('token', access_token)
			this.setMeMedLocale(user.memed_complete || false)
			localStorage.setItem('refresh_token', refresh_token)
			localStorage.setItem(
				'expires_at',
				JSON.stringify(expiresAt.valueOf())
			)
			this.setUserState()
		}
	}

	setMeMedLocale(value: boolean) {
		localStorage.setItem('meMedComplete', JSON.stringify(value))
	}

	getMeMedLocale(): boolean {
		const value = localStorage.getItem('meMedComplete')
		return value ? JSON.parse(value) : false
	}

	setUserState(): void {
		const token = localStorage.getItem('token') || ''
		if (token) {
			let { user } = this.helpers.decodePayloadJWT(token)
			const { clients } = user

			user.clients = this.getClients(clients)

			this.store.dispatch(
				AddUser({
					...this.helpers.converterSnakeToCamelCase(user),
				})
			)
		} else {
			this.store.dispatch(ClearUser())
		}
	}

	getRole(): string[] {
		const roles = localStorage.getItem('roles')
		this.roleAs = roles ? JSON.parse(roles) : ['public']
		return this.roleAs
	}

	getToken(): string {
		return localStorage.getItem('token') || ''
	}

	getRefreshToken(): string {
		return localStorage.getItem('refresh_token') || ''
	}

	getExpiration() {
		const expiration = localStorage.getItem('expires_at')
		const expiresAt = expiration ? JSON.parse(expiration) : ''
		return expiresAt ? moment(expiresAt) : ''
	}

	getUserLocale(): any {
		const token = localStorage.getItem('token') || ''
		const { user } = this.helpers.decodePayloadJWT(token)
		return user
	}

	getClients(clients: any): Client[] {
		const listClients: [string, Client][] = Object.entries(clients)

		return listClients.map((client, i) => {
			return {
				id: listClients[i][0],
				name: listClients[i][1].name,
				logo: listClients[i][1].logo,
				memed: listClients[i][1].memed,
			}
		})
	}

	isLoggedIn() {
		this.isLogin = moment().isBefore(this.getExpiration())
		return this.isLogin
	}

	redirectAuth(): void {
		const userRole = this.getRole()
		let redirectUrl = ''

		if (userRole.includes('admin') || userRole.includes('manager')) {
			redirectUrl = environment.redirects.roles['admin']
		} else {
			redirectUrl = environment.redirects.roles['public']
		}

		this.router.navigate([redirectUrl])
	}

	getFile(path: string) {
		const token = this.getToken()
		const options = {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		}
		return fetch(path, options).then((res) => res.blob())
	}
}
