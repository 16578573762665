<div class="w-100">
    <div class="row">
        <div class="d-flex justify-content-end">
            <button
                type="button"
                (click)="toggleFormContract()"
                class="btn btn-sm btn-light btn-active-color-dark"
            >
                <span class="svg-icon svg-icon-3">
                    <app-svg name="plus"></app-svg>
                </span>
                Adicionar novo contrato
            </button>
        </div>
    </div>

    <app-form-client
        *ngIf="showFormContract"
        [client]="client"
        [contract]="true"
        (sentProtocol)="addContract($event)"
    ></app-form-client>

    <div *ngFor="let contract of contracts">
        <div class="mt-10 wrapper-contract border border-bottom-2 p-6">
            <div class="row d-flex justify-content-between align-items-center mb-10">
                <h3 class="col-8 color-gray-500 m-0">
                    {{ contract.contracts_type.name }}
                </h3>
                <span class="col-4 d-flex justify-content-end">
                    <i class="d-flex align-items-center">
                        criado em: {{ helpers.getDate(contract.created_at) }}
                    </i>
                </span>
            </div>

            <p class="content-contract" [innerHTML]="contract.content"></p>
        </div>
    </div>

    <div class="w-100" *ngIf="contracts && !contracts.length">
        <h4 class="text-danger text-center mt-3">
            Não existe contratos relacionados a este cliente
        </h4>
    </div>
</div>
