import { Component, Input, OnInit } from '@angular/core'
import { ProgressItem } from '../../models/progress'

@Component({
	selector: 'app-progress',
	templateUrl: './progress.component.html',
	styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {
	@Input() progress?: ProgressItem
	result: number = 0

	constructor() {}

	ngOnInit(): void {}

	setValueProgress(val: any) {
		return (this.result = (100 / 100) * val)
	}
}
