import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { Select2OptionData } from 'ng-select2';
import { requestCallback } from 'src/app/models/requestCallback';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'select2';
import { device, deviceSingle } from 'src/app/models/device';
import { protocolsDefault } from 'src/app/mocks/protocols-default';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPreferrersComponent } from '../modal-preferrers/modal-preferrers.component';

@Component({
  selector: 'app-form-protocol',
  templateUrl: './form-protocol.component.html',
  styleUrls: ['./form-protocol.component.scss']
})
export class FormProtocolComponent implements OnInit {
    @Input() form: FormGroup | any = new FormGroup(Input())
    @Input() clientId: string = ''
    @Input() protocolId: string = ''
    @Input() examsClient: device[] = []
    @Input() protocolsClient: device[] = []
    @Output() submiting = new EventEmitter()
    devicesAndExams: device[] = []
    listExamsDefinition: Array<Select2OptionData> = []
    listDevices: Array<Select2OptionData> = []
    optionsCapture: Array<Select2OptionData> = []
    amountDevices: number[] = [ 1 ]
    examsSent: any[] = []
    requests: requestCallback[] = []
    errors: any[] = []
    loading: boolean = false
    itemsRemoveds: device[] = []
    devicesChanges: string[] = []
    devicesDeleteds: string[] = []
    protocolExamsDeleteds: string[] = []
    dataPreferrers: deviceSingle[] = []
    // @ts-ignore
    public options: Options;

    constructor(
        private clientService: ContractClientService,
        public helpers: HelpersService,
        private toast: ToastrService,
        private modalDefault: NgbModal,
    ) {}

    ngOnInit(): void {
        this.options = {
            width: '300',
            multiple: true
        };

        this.optionsCapture = [
            { text: 'CAPTURAR EXAME', id: '' },
            { text: 'cabin', id: 'cabin' },
            { text: 'mobile', id: 'mobile' },
        ]

        this.getExamsDefinition()
        this.getDeviceModels()
        this.verfiyExamsClientExist()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['clientId'] && this.form.valid) {
            this.onSubmit()
        }
    }

    getDeviceModels() {
        const self = this
        this.clientService.getDeviceModels({
            fnSuccess(data) {
                self.listDevices = self.formatSelect2(data)
                self.listDevices.unshift({ text: "APARELHOS", id: '' })
            }, fnError(err) { console.warn(err) }
        })
    }

    getExamsDefinition() {
        const self = this
        this.clientService.getExamsDefinition({
            fnSuccess(data) {
                self.listExamsDefinition = self.formatSelect2(data)
            }, fnError(err) {
                console.warn(err)
            }
        })
    }

    public formatSelect2(list: any[]) {
        return list.map((item: any) => ({ text: item.name, id: item.id }))
    }

    verfiyExamsClientExist() {
        const data = this.examsClient.length ? this.examsClient : protocolsDefault
        if(data.length) {
            for(let i = 0; i < data.length; i++) {
                if(i > 0) {
                    const lastAmount = this.amountDevices[this.amountDevices.length - 1]
                    this.amountDevices.push(lastAmount + 1)
                }
                this.devicesAndExams.push(data[i])
            }
        }

        if(data[0].examsSent) {
            data[0].examsSent.forEach((examSent: any) => {
                this.examsSent.push(examSent)
            })
        }

        if(this.protocolsClient && this.protocolsClient.length) {
            this.protocolsClient.forEach(({ protocolsExams, capturedBy, device }) => {
                protocolsExams?.forEach(({ preferred, exam_id }) => {
                    const preferredExists = this.dataPreferrers.find((item) => item.exam === exam_id)
                    if(preferred && !preferredExists) {
                        this.dataPreferrers.push({ device, capturedBy, exam: exam_id, preferred })
                    }
                })
            })
        }
    }

    onDevicesSelecteds(device: any, idx: number) {
        if(device) {
            this.devicesAndExams[idx].device = device
            this.invalidSubmitEmit()
        }
    }

    onExamsSelects(examsList: any, idx: number) {
        if(examsList?.length) {
            this.devicesAndExams[idx].exams = examsList
            this.invalidSubmitEmit()
        }
    }

    onCaptured(captured: any, idx: number) {
        if(captured) {
            this.devicesAndExams[idx].capturedBy = captured
            this.invalidSubmitEmit()
        }
    }

    invalidSubmitEmit() {
        const { exams, capturedBy, device } = this.devicesAndExams[0]
        if(!exams?.length || !capturedBy || !device) {
            this.submiting.emit({ submit: false })
        } else {
            this.submiting.emit({ submit: true })
        }
    }

    removingDevice(id: number) {
        if(this.examsClient.length) {
            this.examsClient.splice(id, 1)
            if(this.devicesAndExams[id].devicesSent) {
                this.itemsRemoveds.push(this.devicesAndExams[id])
            }
        }
        this.amountDevices.splice(id, 1);
        this.devicesAndExams.splice(id, 1);
    }

    addingDevice() {
        const lastAmount = this.amountDevices[this.amountDevices.length - 1]
        this.amountDevices.push(lastAmount + 1)
        this.devicesAndExams.push({ device: '', exams: [], capturedBy: '' })
    }

    sendProtocolsCLient(idProtocol: string) {
        if(this.clientId && idProtocol) {
            const self = this
            const data = {
                context_id: this.clientId,
                protocol_id: idProtocol
            }
            this.clientService.sendProtocolsCLient(this.clientId, data, {
                fnSuccess(data) {
                    self.toast.success('Protocolos sincronizado com cliente')
                },
                fnError(err) {
                    self.errors.push(err)
                    self.toast.error('Erro na associação do protocolo ao cliente')
                    console.warn(err)
                }
            })
        }
    }

    verifyItemMultiple(data: any, callback: Function) {
        const itemsToRemove: any[] = []

        if(data) {
            this.examsSent.forEach((exam: any) => {
                const { exam_id, protocol_exam, multiple } = exam

                data.forEach((item: any) => {
                    item.devicesSent.forEach(((deviceDeleted: any) => {
                        if(
                            deviceDeleted.protocol_exam === protocol_exam &&
                            exam.device === item.device &&
                            exam.capturedBy === item.capturedBy
                        ) {
                            if(multiple) {
                                itemsToRemove.push({
                                    exam_id,
                                    protocol_exam: deviceDeleted.protocol_exam,
                                    device_id: deviceDeleted.device_id,
                                    multiple: multiple
                                })
                            } else {
                                itemsToRemove.push({
                                    exam_id,
                                    protocol_exam: deviceDeleted.protocol_exam,
                                    device_id: deviceDeleted.device_id
                                })
                            }
                        }
                    }))
                })
            })
            if (callback && itemsToRemove.length) callback(itemsToRemove)
        }
    }

    removeDeviceAndExamRegistred(callback?: Function) {
        if(this.itemsRemoveds.length) {
            this.verifyItemMultiple(this.itemsRemoveds, (dataRemoves: any) => {
                dataRemoves.forEach((itemRemoved: any, index: number) => {
                    this.deleteDevice(itemRemoved, index)
                })
            })

            if(callback) callback()
            return
        }
        if(callback) callback()
    }

    getExamsSent() {
        const itemsSents: any[] = []

        if(this.examsSent.length) this.examsSent.forEach((exam: any) => itemsSents.push(exam))

        this.protocolsClient.forEach((protocol: device) => {
            protocol.protocolsExams?.forEach((item: any) => {
                const { exam_id, protocol_exam } = item

                if(!itemsSents.includes(protocol_exam)) {
                    this.examsSent.push({
                        exam_id,
                        protocol_exam,
                        device: protocol.device,
                        capturedBy: protocol.capturedBy
                    })
                    itemsSents.push(protocol_exam)
                } else if (itemsSents.includes(protocol_exam)) {
                    const itemExam = this.examsSent.find((exam: any) => {
                        return exam.protocol_exam === protocol_exam
                    })
                    itemExam.multiple = true
                    this.examsSent.push({
                        exam_id,
                        protocol_exam,
                        device: protocol.device,
                        multiple: true,
                        capturedBy: protocol.capturedBy
                    })
                }
            })
        })
    }

    deleteDevice(data: any, index: number) {
        if(data) {
            const { protocol_exam, multiple, device_id } = data
            const DELETEDS: requestCallback = {
                id: index,
                fn: (id: number) => {
                    this.deleteDeviceModels(protocol_exam, device_id, () => {
                        if(!multiple) {
                            this.deleteProtocolsExams(protocol_exam, id)
                        } else {
                            this.requests[id].status = 'finalized'
                        }
                    })
                },
                status: 'queue'
            }
            this.requests.push(DELETEDS)
        }
    }

    examAdd(data: any, index: number) {
        const { device, captured_by, exam } = data

        if(data) {
            const deviceList: any[] = []
            const EXAMADDED: requestCallback = {
                id: index,
                fn: (id: number) => {
                    const dataExam = {
                        protocol: this.protocolId,
                        examDefintion: exam,
                    }
                    this.sendExams(dataExam, id, (protocol_exam_id: string) => {
                        const deviceExists = deviceList.find((deviceExist: any) => {
                            return deviceExist.device === device && deviceExist.protocol === protocol_exam_id
                        })

                        if(!deviceExists) {
                            deviceList.push({ device, protocol: protocol_exam_id })
                            const dataPreferred = { device, exam, capturedBy: captured_by }
                            const dataDevice = {
                                device_model_id: device,
                                protocol_exam_id,
                                captured_by,
                                preferred: this.getDevicePreferrer(dataPreferred)
                            }

                            this.sendDeviceModels(dataDevice)
                        }
                    })
                },
                status: 'queue'
            }
            this.requests.push(EXAMADDED)
        }
    }

    addDeviceAndExamClient(examsAddeds: any, callback?: Function) {
        if(examsAddeds.length) {
            examsAddeds.forEach((item: any, index: number) => {
                const data = { device: item.device, captured_by: item.capturedBy }
                if(item.exams && item.exams.length) {
                    item.exams.forEach((exam: string, idx: number) => {
                        const dataExam = { ...data, exam }
                        this.examAdd(dataExam, idx)
                    })
                } else {
                    const dataExam = { ...data, exam: item.exam }
                    this.examAdd(dataExam, index)
                }
            })

            if(callback) callback()
            return
        }
        if(callback) callback()
    }

    removeDeviceAndExamClient(itemsRemoveds: any, callback?: Function) {
        if(itemsRemoveds.length) {
            itemsRemoveds.forEach((item: any, index: number) => {
                const { protocol_exam, device_id } = item
                const listItemsRemoveds: any[] = []

                this.examsSent.forEach((exam: any) => {
                    if(exam.protocol_exam === protocol_exam) {
                        if(exam.multiple) {
                            listItemsRemoveds
                                .push({ device_id, protocol_exam, multiple: exam.multiple })
                                return
                        }
                        listItemsRemoveds.push({ device_id, protocol_exam })
                    }
                })

                listItemsRemoveds.forEach((itemRemoved: any) => {
                    this.deleteDevice(itemRemoved, index)
                })
            })

            if(callback) callback()
            return
        }
        if(callback) callback()
    }

    deleteOrAddExams(deviceExam: any, protocol: any, callback?: Function, isDeviceEdit?: boolean) {
        const examsToRemove: any[] = []
        const examsRemoves: any[] = []

        const examsToAdd: any[] = []
        const examsAddeds: any[] = []

        deviceExam.exams.forEach((exam: string) => {
            if(!protocol.exams.includes(exam) && !examsAddeds.includes(exam)) {
                examsAddeds.push(exam)
                examsToAdd.push({
                    exam,
                    device: deviceExam.device,
                    capturedBy: deviceExam.capturedBy
                })
            } else {
                protocol.exams.forEach((examProtocol: string) => {
                    if(
                        !deviceExam.exams.includes(examProtocol) &&
                        protocol.devicesSent?.length
                    ){
                        protocol.devicesSent.forEach((protocolDevice: any) => {
                            const { protocol_exam, device_id, exam_id } = protocolDevice
                            if (exam_id === examProtocol && !examsRemoves.includes(device_id)) {
                                examsToRemove.push({ protocol_exam, device_id })
                                examsRemoves.push(device_id)
                            }
                        })
                    } else if (isDeviceEdit && !examsAddeds.includes(exam)) {
                        examsAddeds.push(exam)
                        examsToAdd.push({
                            exam,
                            device: deviceExam.device,
                            capturedBy: deviceExam.capturedBy
                        })
                    }
                })
            }
        })

        this.addDeviceAndExamClient(examsToAdd, () => {
            this.removeDeviceAndExamClient(examsToRemove, () => {
                if(callback) callback()
            })
        })
    }

    controlerDeviceAndExams(deviceExams: any, callback?: Function) {
        const lengthProtocols = this.protocolsClient.length - 1
        this.protocolsClient.forEach((protocol: device, index: number) => {
            deviceExams.forEach((deviceExam: any) => {
                if(deviceExam.index === index) {
                    if(
                        deviceExam.capturedBy === protocol.capturedBy &&
                        deviceExam.device === protocol.device
                    ) {
                        this.deleteOrAddExams(deviceExam, protocol, () => {

                            if(index === lengthProtocols && callback) {
                                callback()
                            }
                        })
                        return
                    }

                    if (
                        deviceExam.device !== protocol.device &&
                        deviceExam.capturedBy !== protocol.capturedBy
                    ) {
                        protocol.devicesSent?.forEach((deviceProtocol) => {
                            const DEVICESDELETEDS: requestCallback = {
                                id: index,
                                fn: (id: number) => {
                                    const data = { captured_by: deviceExam.capturedBy }
                                    this.editDeviceModel(protocol, data, () => {
                                        const { protocol_exam, device_id, exam_id } = deviceProtocol

                                        this.deleteDeviceModels(protocol_exam, device_id, () => {
                                            const dataPreferred = {
                                                capturedBy: deviceExam.capturedBy,
                                                exam: exam_id,
                                                device: deviceExam.device
                                            }

                                            const dataDevice = {
                                                captured_by: deviceExam.capturedBy,
                                                protocol_exam_id: protocol_exam,
                                                device_model_id: deviceExam.device,
                                                preferred: this.getDevicePreferrer(dataPreferred)
                                            }
                                            this.sendDeviceModels(dataDevice, () => {
                                                this.requests[id].status = 'finalized'
                                            })
                                        })
                                    })
                                },
                                status: 'queue'
                            }
                            this.requests.push(DEVICESDELETEDS)
                            this.deleteOrAddExams(deviceExam, protocol, () => {
                                if(index === lengthProtocols && callback) callback()
                            })
                        })
                        return
                    }

                    if (
                        deviceExam.device !== protocol.device &&
                        deviceExam.capturedBy === protocol.capturedBy
                    ) {
                        protocol.devicesSent?.forEach((deviceProtocol) => {
                            const { protocol_exam, device_id } = deviceProtocol
                            const DEVICESDELETEDS: requestCallback = {
                                id: index,
                                fn: (id: number) => {
                                    this.deleteDeviceModels(protocol_exam, device_id, () => {
                                        this.requests[id].status = 'finalized'
                                    })
                                },
                                status: 'queue'
                            }
                            this.requests.push(DEVICESDELETEDS)
                            this.deleteOrAddExams(deviceExam, protocol, () => {
                                if(index === lengthProtocols && callback) callback()
                            }, true)
                        })

                        return
                    }

                    if (
                        deviceExam.capturedBy !== protocol.capturedBy &&
                        deviceExam.device === protocol.device
                    ) {
                        const data = { captured_by: deviceExam.capturedBy }

                        const CHANGECAPTURED: requestCallback = {
                            id: index,
                            fn: (id: number) => {
                                this.editDeviceModel(protocol, data, () => {
                                    this.requests[id].status = 'finalized'
                                })
                            },
                            status: 'queue'
                        }
                        this.requests.push(CHANGECAPTURED)
                        this.deleteOrAddExams(deviceExam, protocol, () => {
                            if(index === lengthProtocols && callback) callback()
                        })
                    }

                }
                else {
                    if(callback) callback()
                }
            })
        })
    }

    editDeviceModel(protocol: any, data: any, callback?: Function) {
        protocol.devicesSent?.forEach((device: any, index: number) => {
            const { device_id, protocol_exam, exam_id } = device
            const dataPreferred = {
                    device: protocol.device, exam: exam_id, capturedBy: protocol.capturedBy
                }

            data.preferred = this.getDevicePreferrer(dataPreferred)

            if(!this.devicesChanges.includes(device_id)){
                this.devicesChanges.push(device_id)
                this.clientService.editDeviceModel(protocol_exam, device_id, data, {
                    fnFinalized() {
                        if(index === 0 && callback) callback()
                    },
                    fnSuccess(data) {},
                    fnError(e) { console.warn(e) }
                })
            } else {
                if(index === 0 && callback) callback()
            }
        })
    }

    editDeviceAndExamsClient() {
        if(this.protocolsClient.length) {
            this.getExamsSent()
            const examsToAdd: any = []
            const addOrDeleteExams: any = []

            this.removeDeviceAndExamRegistred(() => {
                this.devicesAndExams.forEach((deviceExam: device, index: number) => {
                    if(!deviceExam.protocolsExams?.length) {
                        examsToAdd.push(deviceExam)
                        return
                    }

                    const data = { ...deviceExam, index }
                    addOrDeleteExams.push(data)
                })
                this.controlerDeviceAndExams(addOrDeleteExams, () => {
                    this.addDeviceAndExamClient(examsToAdd, () => {
                        this.statesSendExames()
                    })
                })
            })
        }
    }

    deleteDeviceModels(protocol_exam_id: string, device: string, callback?: Function) {
        const self = this
        if(
            !this.devicesDeleteds.includes(device) &&
            !this.protocolExamsDeleteds.includes(protocol_exam_id)
        ) {
            this.devicesDeleteds.push(device)
            this.clientService.deleteDeviceModel(protocol_exam_id, device, {
                fnSuccess(data) {
                    if (callback) callback()
                }, fnError(e) {
                    self.errors.push(e)
                    console.warn('error', e)
                }
            })
        } else {
            if (callback) callback()
        }
    }

    deleteProtocolsExams(protocol_exam_id: string, requestId: number) {
        const self = this
        if(!this.protocolExamsDeleteds.includes(protocol_exam_id)) {
            this.protocolExamsDeleteds.push(protocol_exam_id)
            this.clientService.deleteProtocolsExams(this.protocolId, protocol_exam_id, {
                fnSuccess(data) {
                    self.requests[requestId].status = 'finalized'
                }, fnError(e) {
                    self.errors.push(e)
                    console.warn(e)
                }
            })
        } else {
            this.requests[requestId].status = 'finalized'
        }
    }

    sendExams(dataExam: any, requestIndex: number, callback?: Function) {
        const { protocol, examDefintion } = dataExam
        const self = this
        const examExist: any = this.examsSent.find((sent) => sent.exam_id == examDefintion)
        const data = { exam_definition_id: examDefintion }

        if(!examExist) {
            this.clientService.sendProtocolsExams(protocol, data, {
                fnSuccess(id: string) {
                    if(callback) callback(id)
                    self.examsSent.push({ exam_id: examDefintion, protocol_exam: id })
                    self.requests[requestIndex].status = 'finalized'
                }, fnError(err) {
                    self.errors.push(err)
                    self.toast.error('Erro no envio do exame do aparelho')
                }
            })
        } else {
            if(callback) {
                callback(examExist.protocol_exam)
                this.requests[requestIndex].status = 'finalized'
            }
        }
    }

    sendDeviceModels(dataDevice: any, callback?: Function) {
        const {
            captured_by, protocol_exam_id, device_model_id, preferred
        } = dataDevice
        const self = this
        const data = { device_model_id, captured_by, preferred: false }

        if(preferred) {
            data.preferred = true
        }

        if(!this.protocolExamsDeleteds.includes(protocol_exam_id)) {
            this.clientService.sendDeviceModels(protocol_exam_id, data, {
                fnSuccess(data) {
                    if(callback) callback()
                },
                fnError(err) {
                    self.errors.push(err)
                    self.toast.error('Erro no envio do aparelho')
                    console.warn(err)
                }
            })
        } else {
            if(callback) callback()
        }
    }

    getDevicePreferrer(preferred: any) {
        const { exam, device, capturedBy } = preferred

        const preferrerExists = this.dataPreferrers.find((preferrer: any) => {
            return  preferrer.exam === exam && preferrer.device === device && preferrer.capturedBy === capturedBy
        })

        return !!preferrerExists
    }

    mountingRequests(protolcolId: string) {
        let countExams = 0
        if(this.devicesAndExams.length) {
            this.devicesAndExams.forEach((item) => {
                item.exams.forEach((exam) => {
                    const dataPreferred = { exam, device: item.device, capturedBy: item.capturedBy }
                    const preferred = this.getDevicePreferrer(dataPreferred)
                    const dataExam = { examDefintion: exam, protocol: protolcolId }
                    const REQUEST = {
                        id: countExams,
                        fn: (id: number) => {
                            this.sendExams(dataExam, id, (examId: string) => {
                                const dataDevice = {
                                    device_model_id: item.device,
                                    protocol_exam_id: examId,
                                    captured_by: item.capturedBy,
                                    preferred
                                }
                                this.sendDeviceModels(dataDevice)
                            })
                        },
                        status: 'queue'
                    }
                    countExams++
                    this.requests.push(REQUEST)
                })
            })
            this.statesSendExames()
        }
    }

    statesSendExames() {
        this.loading = true
        let countRequests = 0
        if(!this.requests.length) {
            const itemEddited = this.dataPreferrers.some((item) => !item.preferred)
            if(itemEddited) {
                this.toast.success('Iniciando atualizando de aparelhos com preferencia')
                this.setPreferrers(() => {
                    this.submiting.emit({ submited: true })
                    this.loading = false
                })
                return
            }
            this.loading = false
            return
        }

        let interval = setInterval(()=> {
            if(countRequests < this.requests.length && !this.errors.length) {
                let request = this.requests[countRequests]
                if(request.status == 'finalized') {
                    countRequests++;
                } else {
                    if(request.status == 'queue') {
                        request.status = 'initialized'
                        request.fn(countRequests)
                    }
                }
            } else {
                clearInterval(interval)
                if(this.errors.length) {
                    this.toast.error('aconteceu algum erro, tente novamente')
                }
                this.loading = false
                this.submiting.emit({ submited: true })
                if(!this.protocolId && !this.protocolsClient.length) {
                    this.helpers.redirectPage(100, `/panel/cliente/${this.clientId}`)
                }
                this.requests = []
            }
        }, 300)
    }

    setPreferrers(callback: Function) {
        let countExams = 0
        const self = this
        this.devicesAndExams.forEach(({ device, devicesSent, capturedBy }) => {
            devicesSent?.forEach((deviceSent) => {
                const { exam_id, protocol_exam, device_id } = deviceSent
                const dataPreferred = { exam: exam_id, device, capturedBy }
                const preferred = this.getDevicePreferrer(dataPreferred)
                const data = {
                    captured_by: capturedBy,
                    preferred
                }
                const EDITPREFERRED = {
                    id: countExams,
                    fn: (id: number) => {
                        this.clientService.editDeviceModel(protocol_exam, device_id, data, {
                            fnSuccess(data) {
                                self.requests[id].status = 'finalized'
                                if(id === self.requests.length && callback) callback()
                            }, fnError(err) {
                                console.warn(err)
                            }
                        })
                    },
                    status: 'queue'
                }
                countExams++
                this.requests.push(EDITPREFERRED)
            })
        })

        this.statesSendExames()
    }

    sendProtocol(data: any) {
        const self = this
        this.clientService.sendProtocol(data, {
            fnSuccess({ id }) {
                self.mountingRequests(id)
                self.sendProtocolsCLient(id)
                self.toast.success('Protocolos enviados com sucesso')
            }, fnError(err) {
                self.toast.error('Erro no envio do protocolo')
                console.warn(err)
            }
        })
    }

    editProtocol(data: any) {
        const self = this
        this.clientService.editProtocol(this.protocolId, data, {
            fnSuccess(data) {
                self.editDeviceAndExamsClient()
                self.toast.success('Protocolos atualizados com sucesso')
            }, fnError(err) {
                self.toast.error('Erro no envio do protocolo')
                console.warn(err)
            }
        })
    }

    onSubmit() {
        const { nameProtocol, frontendDescription, frontendName, description } = this.form.value
        const body = { frontendDescription, frontendName, description }
        const data = {
            name: nameProtocol,
            ... this.helpers.converterCamelCaseToSnake(body)
        }

        if(this.form.valid && this.clientId) {
            if(this.protocolsClient.length) {
                data.execute_exams_in_order = false
                this.editProtocol(data)
            } else if(!this.protocolId) {
                this.sendProtocol(data, )
            } else {
                this.mountingRequests(this.protocolId)
            }
        }
    }

    showPreferrer() {
        const self = this
        const modalRef = this.modalDefault.open(ModalPreferrersComponent)
        modalRef.componentInstance.listDevicesAndExams = this.devicesAndExams
        modalRef.componentInstance.itemsSents = this.dataPreferrers
        modalRef.componentInstance.callbackConfirmation = (preferrers: deviceSingle[]) => {
            if(self.dataPreferrers.length) {
                preferrers.forEach((preferrer: deviceSingle) => {
                    let indexCurrent = 0
                    const preferrerExists = self.dataPreferrers.find((item, index) => {
                        indexCurrent = index
                        return item.exam === preferrer.exam
                    })

                    if(!preferrerExists) {
                        self.dataPreferrers.push(preferrer)
                    } else {
                        self.dataPreferrers[indexCurrent] = preferrer
                    }
                })
            } else {
                self.dataPreferrers = preferrers
            }

            modalRef.close()
        }
    }
}
