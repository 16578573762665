import { Component, Input, OnInit } from '@angular/core'
import { ListTrends } from '../../models/trends'

@Component({
	selector: 'app-trends',
	templateUrl: './trends.component.html',
	styleUrls: ['./trends.component.scss'],
})
export class TrendsComponent implements OnInit {
	@Input() title: string = ''
	@Input() subTitle: string = ''
	@Input() trends?: ListTrends[] = []

	constructor() {}

	ngOnInit(): void {}
}
