import { ListHeader } from '../models/list-header'

export const listHeaderUsers: ListHeader[] = [
	{
		value: 'online',
		label: '',
		align: 'center',
	},
	{
		value: 'Name',
		label: 'Nome',
		align: 'center',
		className: 'text-left',
	},
	{
		value: 'crm/crmUf',
		label: 'crm/crmuf',
		align: 'center',
		className: 'text-left',
	},
	{
		value: 'roles',
		label: 'cargos',
	},
	{
		value: 'client',
		label: 'clientes',
		align: 'center',
		className: 'text-left',
	},
]

export const listRolesMocks = [
	{
		id: 'admin',
		text: 'Admin',
	},
	{
		id: 'manager',
		text: 'Manager',
	},
	{
		id: 'doctor',
		text: 'Doctor',
	},
	{
		id: 'nurse',
		text: 'Nurse',
	},
	{
		id: 'cabin',
		text: 'Cabin',
	},
	{
		id: 'tablet',
		text: 'Tablet',
	},
]
