<div class="row p-5">
    <div class="col-12 mb-5 d-flex justify-content-center">
        <h3> Exames com mais de um Aparelhos </h3>
    </div>

    <div *ngIf="!this.examslist.length">
        <p class="text-danger text-center"> Sem exames com mulitplos devices </p>
    </div>

    <div *ngIf="examslist.length" [class]="devicesByExam.length ? 'col-3 text-left border-right' : 'col-12 text-center'">
        <ul>
            <li
                class="mb-4 cursor-pointer text-center list-unstyled p-2 list"
                *ngFor="let exam of examslist"
                [class.actived]="exam.id === examSelected?.id"
                (click)="getDeviceByExam(exam)"
            >
                {{ exam.name }}
            </li>
        </ul>
    </div>

    <div class="col-9" *ngIf="devicesByExam.length">
        <h3 class="text-active-dark text-center mb-5">
            Aparelhos do exame de {{ examSelected?.name }}
        </h3>
        <ul>
            <li
                *ngFor="let device of devicesByExam"
                class="mb-4 cursor-pointer list-unstyled p-2 list"
                [class.actived]="getItemActived(device)"
                (click)="setDeviceOfExam(device)"
            >
                {{ device.device }} ({{ device.capturedBy }})
            </li>
        </ul>
    </div>

    <div class="d-flex w-100 justify-content-end mt-7">
        <button
            type="button"
            (click)="close()"
            class="btn btn-danger cursor-pointer m-3"
        >
            Cancelar
        </button>
        <button
            (click)="confirmation()"
            type="button"
            class="btn btn-success cursor-pointer m-3"
        >
            Confirmar
        </button>
    </div>
</div>
