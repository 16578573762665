import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { HelpersService } from '../helpers/helpers.service'

@Injectable({
	providedIn: 'root',
})
export class ClientService {
	constructor(private http: HttpClient, private helpers: HelpersService) {}

	getAllClients(callback: Callbacks): void {
		const self = this
		this.http
			.get<any>(`${environment.api}/clients`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					data = data.map((item: any) => {
						return self.helpers.converterSnakeToCamelCase(item)
					})
					callback.fnSuccess(data)
				},
				(err) => { callback.fnError(err) }
			)
	}

	getClient(id: string, callback: Callbacks): void {
		const self = this
		this.http
			.get<any>(`${environment.api}/clients/${id}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					callback.fnSuccess(
						self.helpers.converterSnakeToCamelCase(data)
					)
				},
				(err) => {
					callback.fnError(err)
					console.warn(err)
				}
			)
	}

	createClient(data: any, callback?: Callbacks): void {
		this.http
			.post<any>(`${environment.api}/clients`, data)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => { callback?.fnSuccess(data) },
				(err) => { callback?.fnError(err) }
			)
	}

	deleteClient(id: string, callback: Function) {
		this.http.delete<any>(`${environment.api}/clients/${id}`).subscribe(
			() => { if (callback) callback() },
			(err) => { console.warn(err) }
		)
	}

	updateClient(id: string, data: any, callback?: Callbacks) {
		this.http
			.patch<any>(`${environment.api}/clients/${id}`, data)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => { callback?.fnSuccess(data) },
				(err) => { console.warn(err) }
			)
	}

    getSpecialtiesClient(client_id?: string | boolean, callback?: Callbacks) {
        let url = `${environment.api}/specialties`
        if (client_id) url = `${environment.api}/specialties?clients=${client_id}`

        this.http
			.get<any>(url).pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				({ items }) => {
                    items = items.map((item: any) => {
                        return {
                            id: item.id,
                            text: item.specialty
                        }
                    })
                    callback?.fnSuccess(items)
                },
				(err) => { console.warn(err) }
			)
    }

    sendSpecialtiesClient(client_id: string, data: any, callback?: Callbacks) {
        this.http
			.post<any>(`${environment.api}/clients/${client_id}/specialties`, data)
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => { callback?.fnSuccess(data) },
				(err) => { console.warn(err) }
			)
    }

    deleteSpecialties(id: string, callback?: Function) {
		this.http.delete<any>(`${environment.api}/specialties/${id}`).subscribe(
			() => { if (callback) callback() },
			(err) => { console.warn(err) }
		)
	}
}
