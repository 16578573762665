<div class="my-0">
	<button
		(click)="activeMenuSup()"
		type="button"
		class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
	>
		<span class="svg-icon svg-icon-2">
			<app-svg name="menu-sub"></app-svg>
		</span>
	</button>
	<div
		[class.show]="active"
		class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px"
	>
		<div class="menu-item px-3">
			<div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">
				{{ title }}
			</div>
		</div>
		<div>
            <ng-content></ng-content>
        </div>
	</div>
</div>
