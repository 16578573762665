import { Component, OnInit } from '@angular/core'
import {
	listProgressMock,
	listTodoMock,
	timelineItemsMock,
	trendsMock,
} from '../../../mocks/components-mocks'
import { User } from '../../../models/user'
import { TimelineItem } from '../../../models/timeline'
import { TodoItem } from '../../../models/todo'
import { ListTrends } from '../../../models/trends'
import { ListMenu } from '../../../models/list-menu'

@Component({
	selector: 'app-components',
	templateUrl: './components.component.html',
	styleUrls: ['./components.component.scss'],
})
export class ComponentsComponent implements OnInit {
	userData?: User
	listProgress?: any
	listTimeline: TimelineItem[] = []
	listTodo: TodoItem[] = []
	listTrends: ListTrends[] = []
	listMenu: ListMenu[] = []
	itemSelected: string = 'Overview'

	constructor() {
		this.userData = {
			id: '12',
			name: 'Tiago',
			lastName: 'Marques',
			birthdate: '12/12/2000',
			email: 'tiago@tiago.com',
			roles: ['admin'],
		}

		this.listTimeline = timelineItemsMock
		this.listTodo = listTodoMock
		this.listProgress = listProgressMock
		this.listTrends = trendsMock

		this.listMenu = [
			{
				title: 'Overview',
				link: '#',
			},
			{
				title: 'Settings',
				link: '#',
			},
		]
	}

	ngOnInit(): void {}

	setIdActiveItem(item: ListMenu): void {
		this.itemSelected = item.title
	}
}
