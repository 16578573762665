<div class="card mb-5 mb-xxl-10">
    <div class="card-header border-0 pt-6" *ngIf="title || buttonsToolbar">
        <div class="card-title">
            {{ title }}
        </div>
        <div class="card-toolbar" *ngIf="buttonsToolbar">
            <div
                class="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
            >
                <div *ngFor="let item of buttonsToolbar">
                    <a
                        class="btn btn-primary"
                        [routerLink]="item.action"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        {{ item.label }}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0 pb-4">
        <ng-content></ng-content>
    </div>
</div>
